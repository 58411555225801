import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Slider from "react-slick"
import {motion} from 'framer-motion';
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';

// import BGImg from "../../assets/img/bg/bdrc-bg.jpg";
import BGImg from "../../assets/img/bg/about-landing-bg.jpg";

import BGImg1 from "../../assets/img/slider/slider_bg_1.jpg";
import BGImg2 from "../../assets/img/slider/slider_bg_8.jpg";
import BGImg3 from "../../assets/img/slider/slider_bg_4.png";
import BGImg4 from "../../assets/img/slider/slider_bg_9.jpg";

import aboutSec5Left from "../../assets/img/features/about-sec5-left.jpg";
import aboutSec5Left2 from "../../assets/img/features/about-sec5-left2.jpg";

import avatar1 from "../../assets/img/testimonial/testi_avatar.png"
import avatar2 from "../../assets/img/testimonial/testi_avatar_02.png" 
import avatar3 from "../../assets/img/testimonial/testi_avatar_03.png"
import Aicon from "../../assets/img/testimonial/qt-icon.png"
import Aicon1 from "../../assets/img/testimonial/review-icon.png"
import BGTest from "../../assets/img/bg/review-bg.jpg";

const Main = () => {
    const [hShow, sethShow] = useState()
    const [animation, setanimation] = useState()


    const home = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        autoplaySpeed:2000,
        centerMode: true,
        centerPadding: "200px",
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "200px",
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const sec3 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const sec5 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    
    const review_carousal = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    let screenWidth = window.innerWidth;

  return (
    <>
        <main>
            <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${BGImg})`}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>About</h2>    
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">About</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about-tests" className="main-content-section blog-area p-relative fix pt-115 pb-100">
            <div className="animations-02"><img src="../../../assets/img/bg/mountain.png" style={{opacity: '0.1'}} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-12">
                            <div className="section-title center-align text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">

                                <h2 className='mb-10'>
                                    NinYanWays
                                </h2>

                                <h3>
                                    What We’re About
                                </h3>
                                <p style={{textAlign: 'center'}}>NinYanWays offers modern yet eco-friendly boutique stays at an unmatched location with artistic interior decoration, an upscale garden cafe, and a cosy indoor lounge . The pristine property offers astonishing lakeside views of apple orchards and lush green forests, through which cheeky wisps of mountain mist linger endlessly.</p>

                                <p style={{textAlign: 'center'}}>Ninyanways is the perfect place to reconnect with oneself. It's known for its calm and serene atmosphere, as a place where you can unwind, lounging in your designer suite or private courtyard. Just picture sitting with your loved one(s) here, under the stars with the sounds of the river serenading you as you breathe in the fresh mountain air.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <BrowserView>
                <section id="home-carousal-d" className="main-content-section slider-area about-img-slider-sec fix p-relative pt-120 pb-115" style={{background: "#f7f5f1"}}>
                        <div className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align mb-50 text-center">
                                    <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} >Your Happiness Is Our Happiness</motion.h2>
                                </div>
                            </div>
                        </div>  
                    <Slider className="slider-active" {...home}>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg1})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg2})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg3})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg4})`, background_size: "cover"}}>
                            </div>
                        </div>
                    </Slider>
                </section>
            </BrowserView>

            <MobileView>
                <section id="home" className="main-content-section slider-area about-img-slider-sec fix p-relative pt-120 pb-90" style={{background: "#f7f5f1"}}>
                    <div className="row justify-content-center">
                        <div className="col-xl-12">    
                            <div className="section-title center-align mb-30 text-center">
                                    {/* <h5>The pleasure of luxury</h5> */}
                                <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} >Your Happiness Is Our Happiness</motion.h2>
                            </div>
                        </div>
                    </div>

                    <Slider className="row slider-active about-sec3-slider" {...sec3}>
                        <div className="col-xl-2">
                            <div className="about-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg1})`}}>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="about-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg2})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="about-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg3})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="about-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg4})`, background_size: "cover"}}>
                            </div>
                        </div>
                    </Slider>
                </section>
            </MobileView>

            <section id="about-tests" className="main-content-section blog-area p-relative fix pt-120 pb-100">
            <div className="animations-02"><img src="../../assets/img/bg/costumer.png" style={{opacity: '0.1'}} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-12">
                            <div className="section-title center-align text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                 {/* <h5>Our Blog</h5> */}
                                <h2 className='mb-30'>
                                Core Values
                                </h2>
                                <p style={{textAlign: 'center'}}><b>Humility</b> - Humbleness is at the top of our core values. We recognize the value of people and the planet and the need to treat each other with love and respect.</p>

                                <p style={{textAlign: 'center'}}><b>Sustainability</b> - We strive to use environmentally friendly methods and products to protect our ecosystem and preserve natural resources.</p>

                                <p style={{textAlign: 'center'}}><b>Inclusive Community</b> - We acknowledge absolute freedom, free of prejudices, and welcome everyone with open arms.</p>

                                <p style={{textAlign: 'center'}}><b>Service-Oriented</b> - We exist to provide diverse experiences in a nurturing environment, granting guests a sense of fulfilment deep within.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-ecotour-sec main-content-section about-area about-p pt-120 pb-110 p-relative fix" style={{background: "#f7f5f1"}}>
                {/* <div className="animations-02"><img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-02"/></div> */}
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="about-eco-slider-sec col-lg-6 col-md-12 col-sm-12">
                        <Slider className="row slider-active about-sec5-slider" {...sec5}>
                          
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left} alt="img" />   
                            </div>

                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left2} alt="img" />   
                            </div>
                        </Slider>  
                        </div>
					    <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-05">  
                                    {/* <h5>About Us</h5> */}
                                    <motion.h2  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Ecotourism</motion.h2>                                   
                                </div>
                                <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>NinYanWays cares about the environment and promotes sustainable travel. We work hard to integrate nature-based practices, while ensuring locals are ecologically educated.</motion.p>

                                <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>We promote and practise eco-friendly policies like food waste prevention, plastic reduction and safe disposal. We use water-efficient plumbing and at least 80% of our cuisine is sourced from the region, while 80% of lighting comes through energy-efficient LED bulbs.</motion.p>
                                {/* <div className="about-content3 mt-30">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-12">
                                            <motion.h4  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>We do not offer:</motion.h4>
                                            <ul className="green mb-30">                                              
                                                <motion.li  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Plastic toiletry bottles</motion.li>

                                                <motion.li  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Plastic straws</motion.li>     

                                                <motion.li  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Plastic cups</motion.li>

                                                <motion.li  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Plastic water bottles</motion.li>

                                                <motion.li  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Plastic cutlery and tableware</motion.li>

                                                <motion.li  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Plastic stirrers</motion.li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="main-content-section testimonial-area pt-120 pb-120 p-relative fix"  >
                <div className="container">
                    <div className="row">
                         <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                <motion.h5  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>A team designing unforgettable moments with great dedication</motion.h5>
                                <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Whispers of Contentment</motion.h2>
                                {/* <p>Proin consectetur non dolor vitae pulvinar. Pellentesque sollicitudin dolor eget neque viverra, sed interdum metus interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper dolor iaculis vel</p> */}
                            </div>
                        </div>
                    </div>

                    {
                        isTablet === true ? 

                            screenWidth > 992 && screenWidth < 1200 ? 
                                (
                                    <Slider className="slider-active" {...review_carousal}>
                                        <div className="review-slider row align-items-center">
                                                <div className="review-box col-lg-4 col-md-6" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Adhideb</h3>
                                                                    </div>
                
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
                
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Kalpana</h3>
                                                                    </div>
                
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                            <br></br>
                                                            </p>
                                                            </div>
                
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Chetan</h3>
                                                                    </div>
                
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
                
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="review-slider row align-items-center">
                                                <div className="review-box col-lg-4 col-md-6" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Ritesh</h3>
                                                                    </div>
                
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
                
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Shushank</h3>
                                                                    </div>
                
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                            Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                            Cannot wait to go back because this property deserves being visited more than once”
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
                
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Purva</h3>
                                                                    </div>
                
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                            
                                                            </p>
                                                            </div>
                
                                                    </div>
                                                </div>
                                        </div>
                                    </Slider>
                                )
                            :

                            (
                                <Slider className="slider-active" {...review_carousal}>
                                <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Adhideb</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Kalpana</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>

                                </div>
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Chetan</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Ritesh</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
                                <div className="review-slider row align-items-center">

                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Shushank</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                    Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                    Cannot wait to go back because this property deserves being visited more than once”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Purva</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                    
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                </div>
                                </Slider>
                            )
                        :

                        isMobile === true ? 
                            (
                                <Slider className="slider-active" {...review_carousal}>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Adhideb</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Ritesh</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Kalpana</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Chetan</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Shushank</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                        Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                        Cannot wait to go back because this property deserves being visited more than once”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Purva</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                </Slider>  
                            )
                        :
                        <Slider className="slider-active" {...review_carousal}>
                            <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                        <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                <div className="testi-author mb-10">
                                                    <div className="review-content ta-info" >
                                                        <div className='review-content-name'>
                                                        <h3>Adhideb</h3>
                                                        </div>

                                                        <div className="qt-img">
                                                        <img src={Aicon} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-icon mb-10">
                                                    <img src={Aicon1} alt="img"/>
                                                </div>
                                                <div className='mb-20'>
                                                <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                </p>
                                                </div>

                                        </div>
                                    </div>
                                    <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                        <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                <div className="testi-author mb-10">
                                                    <div className="review-content ta-info" >
                                                        <div className='review-content-name'>
                                                        <h3>Kalpana</h3>
                                                        </div>

                                                        <div className="qt-img">
                                                        <img src={Aicon} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-icon mb-10">
                                                    <img src={Aicon1} alt="img"/>
                                                </div>
                                                <div className='mb-20'>
                                                <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                <br></br>
                                                </p>
                                                </div>

                                        </div>
                                    </div>
                                    <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                        <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                <div className="testi-author mb-10">
                                                    <div className="review-content ta-info" >
                                                        <div className='review-content-name'>
                                                        <h3>Chetan</h3>
                                                        </div>

                                                        <div className="qt-img">
                                                        <img src={Aicon} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-icon mb-10">
                                                    <img src={Aicon1} alt="img"/>
                                                </div>
                                                <div className='mb-20'>
                                                <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                <br></br>
                                                <br></br>
                                                </p>
                                                </div>

                                        </div>
                                    </div>
                            </div>
                            <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                        <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                <div className="testi-author mb-10">
                                                    <div className="review-content ta-info" >
                                                        <div className='review-content-name'>
                                                        <h3>Ritesh</h3>
                                                        </div>

                                                        <div className="qt-img">
                                                        <img src={Aicon} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-icon mb-10">
                                                    <img src={Aicon1} alt="img"/>
                                                </div>
                                                <div className='mb-20'>
                                                <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                <br></br>
                                                <br></br>
                                                </p>
                                                </div>

                                        </div>
                                    </div>
                                    <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                        <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                <div className="testi-author mb-10">
                                                    <div className="review-content ta-info" >
                                                        <div className='review-content-name'>
                                                        <h3>Shushank</h3>
                                                        </div>

                                                        <div className="qt-img">
                                                        <img src={Aicon} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-icon mb-10">
                                                    <img src={Aicon1} alt="img"/>
                                                </div>
                                                <div className='mb-20'>
                                                <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                Cannot wait to go back because this property deserves being visited more than once”
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                </p>
                                                </div>

                                        </div>
                                    </div>
                                    <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                        <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                <div className="testi-author mb-10">
                                                    <div className="review-content ta-info" >
                                                        <div className='review-content-name'>
                                                        <h3>Purva</h3>
                                                        </div>

                                                        <div className="qt-img">
                                                        <img src={Aicon} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-icon mb-10">
                                                    <img src={Aicon1} alt="img"/>
                                                </div>
                                                <div className='mb-20'>
                                                <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                
                                                </p>
                                                </div>

                                        </div>
                                    </div>
                            </div>
                        </Slider>
                    }




                </div>
            </section>

        </main>
    </>
  )
}

export default Main