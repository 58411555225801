import { Link } from 'react-router-dom'
import BGImg from "../../../assets/img/bg/room-details-bg.jpg";
import React , { useState , useEffect} from 'react'
import FsLightbox from 'fslightbox-react';

import './NewGallary.css';
import video1 from '../../../assets/video/videoLeft_snowfall.mp4'
import video2 from '../../../assets/video/videoLeft.mp4'
import video3 from '../../../assets/video/bbq-video-trimmed.mp4'

const NewGallary = () => {
    const [activeImage , setActiveImage] = useState(1)

    const [images , setImages] = useState([
   
        '../../../assets/img/img-gallery/gallery-img-1.jpeg',
        '../../../assets/img/img-gallery/gallery-img-2.jpg',
        '../../../assets/img/img-gallery/gallery-img-3.jpg',
        '../../../assets/img/img-gallery/gallery-img-4.jpg',
        '../../../assets/img/img-gallery/gallery-img-5.jpg',
        '../../../assets/img/img-gallery/gallery-img-6.jpg',
        '../../../assets/img/img-gallery/gallery-img-7.jpg',
        '../../../assets/img/img-gallery/gallery-img-8.png',
        '../../../assets/img/img-gallery/gallery-img-9.png',
        '../../../assets/img/img-gallery/gallery-img-10.png',
        '../../../assets/img/img-gallery/gallery-img-11.jpg',
        '../../../assets/img/img-gallery/gallery-img-12.jpeg',
        '../../../assets/img/img-gallery/gallery-img-13.jpg',
        '../../../assets/img/img-gallery/gallery-img-14.jpg',
        '../../../assets/img/img-gallery/gallery-img-15.jpeg',
        '../../../assets/img/img-gallery/gallery-img-16.jpeg',
        '../../../assets/img/img-gallery/gallery-img-17.jpg',
        '../../../assets/img/img-gallery/gallery-img-18.jpg',
        '../../../assets/img/img-gallery/gallery-img-19.jpg',
        '../../../assets/img/img-gallery/gallery-img-20.jpg',
        '../../../assets/img/img-gallery/gallery-img-21.jpg',
        '../../../assets/img/img-gallery/gallery-img-22.jpg',
        '../../../assets/img/img-gallery/gallery-img-23.jpeg',
        '../../../assets/img/img-gallery/gallery-img-24.jpg',
        '../../../assets/img/img-gallery/gallery-img-25.jpg',
        '../../../assets/img/img-gallery/gallery-img-26.jpg',
	])


    const [img1, setImg1] = useState()
    const [img2, setImg2] = useState()
    const [img3, setImg3] = useState()
    const [img4, setImg4] = useState()
    const [img5, setImg5] = useState()
    const [img6, setImg6] = useState()
    const [img7, setImg7] = useState()
    const [img8, setImg8] = useState()
    const [img9, setImg9] = useState()
    const [img10, setImg10] = useState()
    const [img11, setImg11] = useState()
    const [img12, setImg12] = useState()
    const [img13, setImg13] = useState()
    const [img14, setImg14] = useState()
    const [img15, setImg15] = useState()
    const [img16, setImg16] = useState()
    const [img17, setImg17] = useState()
    const [img18, setImg18] = useState()
    const [img19, setImg19] = useState()
    const [img20, setImg20] = useState()
    const [img21, setImg21] = useState()
    const [img22, setImg22] = useState()
    const [img23, setImg23] = useState()
    const [img24, setImg24] = useState()
    const [img25, setImg25] = useState()
    const [img26, setImg26] = useState()

    const [toggler, setToggler] = useState(false);

    const [tabMenu, tabActive] = useState({all : true})

    const galleryHeight=()=>{
        if(tabMenu.all){
            return "h1200"
        }else if(tabMenu.financial){
            return "h600"
        }else {
            return "h300"
        }
    }
    
   
  return (
    <>
        <main>
            <section className="breadcrumb-area d-flex align-items-center" style={{ backgroundImage : `url(${BGImg})`}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>Gallery</h2>    
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Gallery </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="gallary-sec main-content-section profile fix pt-105 pb-105">

                <div id="gallery" class="container-fluid">
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(1) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-1.jpeg" class="img-responsive" />
                    </Link>  
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(10) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-10.png" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(2) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-2.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(3) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-3.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(4) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-4.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(5) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-5.jpg" class="img-responsive" />
                    </Link> 
                    <video class="vid" autoPlay muted loop>
                        <source src={video2} type="video/mp4">
                        </source>
                    </video>


                    
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(6) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-6.jpg" class="card img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(7) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-7.jpg" class="img-responsive" />
                    </Link> 
                    <video class="vid" autoPlay muted loop>
                        <source src={video1} type="video/mp4">
                        </source>
                    </video>
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(8) }}>  
                        <img src="../../../assets/img/img-gallery/gallery-img-8.png" class="img-responsive" /> 
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(9) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-9.png" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(11) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-11.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(12) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-12.jpeg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(13) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-13.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(14) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-14.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(15) }}> 
                        <img src="../../../assets/img/img-gallery/gallery-img-15.jpeg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(16) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-16.jpeg" class="card img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(17) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-17.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(18) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-18.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(19) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-19.jpg" class="img-responsive" />
                    </Link> 



                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(20) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-20.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(21) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-21.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(22) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-22.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(23) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-23.jpeg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(24) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-24.jpg" class="img-responsive" />
                    </Link> 
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(25) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-25.jpg" class="img-responsive" />
                    </Link> 
                    <video class="vid" autoPlay muted loop>
                        <source src={video3} type="video/mp4">
                        </source>
                    </video>
                    <Link to="#" className="gallery-link popup-image" onClick={() => { setToggler(!toggler); setActiveImage(26) }}>
                        <img src="../../../assets/img/img-gallery/gallery-img-26.jpg" class="img-responsive" />
                    </Link> 

                    
                    {/* <img src="../../../assets/img/img-gallery/gallery-img-8.png" class="img-responsive" />  */}

                </div>

                <div id="myModal" class="modal fade" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-body">
                            </div>
                        </div>

                    </div>
                </div>

            </section>   
        </main>
        
        <FsLightbox
            toggler={toggler}
            sources={images}
            slide={activeImage}
        />
        
    </>
  )
}

export default NewGallary