import { Link , useParams} from 'react-router-dom'
import React from 'react'
import BGImg from "../../../assets/img/bg/plant-room-bg.jpg";

const Main = ({url, roomName, description}) => {

    const { id } = useParams();
    
 
    const rooms ={
        boho: {
            title: "Boho Room",
            description: "Our bohemian room is designed with a trendy lifestyle that revolves around nature, love, art, and creativity in mind. It’s for those fond of spacious living, who appreciate the use of natural fibres like linen and cotton. Incorporating a clean palette of earthy colours, we've combined nature-inspired furnishing with modern features, providing all the amenities needed for a comfortable stay, with touches of boho art.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/boho/boho_3.jpg',
            img2: '../../../assets/img/room-img/boho/boho_1.jpg',
            img3: '../../../assets/img/room-img/boho/boho_2.jpeg',
            img4: '../../../assets/img/room-img/boho/boho_4.jpg',
            img5: '../../../assets/img/room-img/boho/boho_5.jpg'
        },
        himachali: {
            title: "Himachali Room",
            description: "The Himachali room is also spacious, with breathtaking views of the apple orchard, river (Manalsu), pine tree forest (Van Vihar) and mountains enveloping the property. Artefact hangings and curated furniture reflect age-old local design and are bathed in natural light, while the ethnic vibe enchants each visitor with the richness of the regional culture, offering an opportunity to surround oneself with the traditional elements.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/himachali/himachali_1.jpg',
            img2: '../../../assets/img/room-img/himachali/himachali_2.jpg',
            img3: '../../../assets/img/room-img/himachali/himachali_3.jpg',
            img4: '../../../assets/img/room-img/himachali/himachali_4.jpg',
            img5: '../../../assets/img/room-img/himachali/himachali_7.jpg'
        },
        deco : {
            title: "Art Deco",
            description: "Check into the Art Deco  - short for the French term, Arts Décoratifs - suite if you find yourself dreaming of the roaring ’20s, a time of cloche hats, finger waves and smooth Jazz. The look is strong, elegant and functional, giving the room an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama. If you’re looking to channel your inner Gatsby or infuse your stay with eclectic glamour, you won’t be disappointed.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/art-deco/art_deco_1.jpeg',
            img2: '../../../assets/img/room-img/art-deco/art_deco_2.jpeg',
            img3: '../../../assets/img/room-img/art-deco/art_deco_3.jpeg',
            img4: '../../../assets/img/room-img/art-deco/art_deco_4.jpeg',
            img5: '../../../assets/img/room-img/art-deco/art_deco_5.jpeg'
        },
        plant :{
            title:"Plant Room",
            description:"If you want to take reminders of the vast landscape home with you, our room focused on flora is the perfect choice. The verdant leaves not only add to the aesthetic value but also have health benefits as houseplants are often embraced for their air-purifying quality. Savour a calm mind in your haven and enjoy the natural light falling on your face as you gaze at the mountain ranges beneath the open sky.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/plant/plant_1.jpeg',
            img2: '../../../assets/img/room-img/plant/plant_2.jpeg',
            img3: '../../../assets/img/room-img/plant/plant_4.jpg',
            img4: '../../../assets/img/room-img/plant/plant_5.jpeg',
            img5: '../../../assets/img/room-img/plant/plant_6.jpeg'
        },
        scandinavian:{
            title: "Scandinavian Room",
            description: "This room’s design is marked by a focus on clean lines, minimalism, and simplicity, without sacrificing beauty. Scandinavians also deeply value nature and spending time outdoors, something that is reflected here by way of uncovered wooden floors and tones. And, of course, live plants are a popular choice when it comes to bringing the outdoors in, while the balcony offers breathtaking views of the river and pine forest, and lots of natural light. ",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/scandinavian/scandinavian_6.jpg',
            img2: '../../../assets/img/room-img/scandinavian/scandinavian_1.jpg',
            img3: '../../../assets/img/room-img/scandinavian/scandinavian_4.jpg',
            img4: '../../../assets/img/room-img/scandinavian/scandinavian_3.jpg',
            img5: '../../../assets/img/room-img/scandinavian/scandinavian_5.jpg'
        },
        river_vintage: {
            title: "Vintage Room",
            description:"This old-school room features stylishly aged brass lamps and has antique-inspired wall frames, a worn rug, and other old-fashioned hardware, transporting you to another time; that’s without losing your footing in the present day, as the vintage elements are balanced with modern, minimalistic design, leaving you with the best of both eras. Sunlight fills the room by day and the balcony overlooks the river and pine forest.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/vintage-river/vintage_river_1.jpg',
            img2: '../../../assets/img/room-img/vintage-river/vintage_river_4.jpg',
            img3: '../../../assets/img/room-img/vintage-river/vintage_river_5.jpg',
            img4: '../../../assets/img/room-img/vintage-river/vintage_river_6.jpg',
            img5: '../../../assets/img/room-img/vintage-river/vintage_river_3.jpg'
        },
        mountain_vintage: {
            title: "Vintage Room",
            description:"This old-school room features stylishly aged brass lamps and has antique-inspired wall frames, a worn rug, and other old-fashioned hardware, transporting you to another time; that’s without losing your footing in the present day, as the vintage elements are balanced with modern, minimalistic design, leaving you with the best of both eras. Sunlight fills the room by day and the balcony overlooks the river and pine forest.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3',
            separate_amenity: true,
            heroImg: '',
            img1: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_1.jpg',
            img2: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_2.jpg',
            img3: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_3.jpg',
            img4: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_4.jpg',
            img5: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_6.jpg'
        },
        mud: {
            title: "Mud Room",
            description: "Built with natural materials like mud and straw, this down-to-earth dwelling incorporates wild elements alongside modern materials, so you can savour countryside living during your cosy stay in the mountains. The vernacular architecture has numerous benefits and is ideal for those who are looking for an organic feel. Also, the room has a large bay window, offering a panoramic view of the apple orchards and both sides of Beas River. ",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc',
            separate_amenity: true,
            heroImg: '',
            img1: '../../../assets/img/room-img/mud/mud_1.jpg',
            img2: '../../../assets/img/room-img/mud/mud_2.jpg',
            img3: '../../../assets/img/room-img/mud/mud_6.jpg',
            img4: '../../../assets/img/room-img/mud/mud_4.jpg',
            img5: '../../../assets/img/room-img/mud/mud_5.jpg'
        },
        rustic: {
            title: "Rustic Room",
            description: `Our rural theme is a good antidote to modern overstimulation, countering the effects of screentime through elements like a shabby-chic paint job and worn fabrics. The rustic room is homey and welcoming, inviting you to curl up under a cosy knit blanket and enjoy the view of many apple and pine trees beneath the waterfalls above Vashisht across the Beas River Beas.
            There's a classic, understated elegance to the décor that makes you feel at peace.`,
            airbnbURL: 'https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7',
            separate_amenity: true,
            heroImg: '',
            img1: '../../../assets/img/room-img/rustic/rustic_1.jpg',
            img2: '../../../assets/img/room-img/rustic/rustic_2.jpg',
            img3: '../../../assets/img/room-img/rustic/rustic_4.jpg',
            img4: '../../../assets/img/room-img/rustic/rustic_5.jpg',
            img5: '../../../assets/img/room-img/rustic/rustic_6.jpg'
        
        },
        stone: {
            title: "Stone Room",
            description: "Features sourced from natural rocks create a wonderful aesthetic impact in and around this room, which has a large bay window offering a panoramic view of the whole valley sandwiching the Beas River. The stonewall within, from the metal-framed ceiling lamps to the bathroom, acts as a backdrop that matches the exterior beauty of the property, making this room a perfect place to escape the nitty-gritty complexity and disorder of everyday life. ",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE',
            separate_amenity: true,
            heroImg: '',
            img1: '../../../assets/img/room-img/stone/stone_1.jpg',
            img2: '../../../assets/img/room-img/stone/stone_2.jpg',
            img3: '../../../assets/img/room-img/stone/stone_4.jpg',
            img4: '../../../assets/img/room-img/stone/stone_5.jpg',
            img5: '../../../assets/img/room-img/stone/stone_6.jpg'
        },
        scandanavian_triple: {
            title: "Scandanavian Triple Room",
            description: "Another ode to simplicity, this room is designed on similar lines as our Scandinavian double room. It is slightly bigger, and extra bedding is available upon request. The triple version also has a balcony facing the mountains that house several beautiful villages, with Rohtang pass as its backdrop and a number of waterfalls in the foreground.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/scandinavian/scandinavian_1.jpg',
            img2: '../../../assets/img/room-img/scandinavian/scandinavian_3.jpg',
            img3: '../../../assets/img/room-img/scandinavian/scandinavian_4.jpg',
            img4: '../../../assets/img/room-img/scandinavian/scandinavian_5.jpg',
            img5: '../../../assets/img/room-img/scandinavian/scandinavian_2.jpg'
        },
        vintage_triple: {
            title: "Vintage Triple Room",
            description: "This room is designed along similar lines as our vintage double room - old-fashioned elements are balanced with modern, minimalistic design - but the triple version is larger and has optional sleeping provisions for a third person. Its large bay window faces the magical mountains surrounding the property, with apple orchard trees abounding at all angles.",
            airbnbURL: 'https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE',
            separate_amenity: false,
            heroImg: '',
            img1: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_1.jpg',
            img2: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_2.jpg',
            img3: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_3.jpg',
            img4: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_4.jpg',
            img5: '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_6.jpg'
        } 
    
    }


  return (
    <>
        <main>
            <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${BGImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>Room Details</h2>    
                                    <div className="breadcrumb-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{rooms[id].title}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-40">
                    <div className="row">
                        {/* <div className="col-sm-12 col-md-12 col-lg-4 order-2">
                            <aside className="sidebar services-sidebar">
                                <div className="sidebar-widget categories">
                                    <div className="widget-content">
                                        <h2 className="widget-title">  Book A Room  </h2>
                                        <div className="booking">
                                            <div className="contact-bg"> 
                                                <form action="mail.php" method="post" className="contact-form mt-30">
                                                    <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="contact-field p-relative c-name mb-20">                                    
                                                            <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                                            <input type="date" id="chackin" name="date"/>
                                                        </div>                               
                                                    </div>
                                                    <div className="col-lg-12">                               
                                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                                        <label><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                                            <input type="date" id="chackout" name="date"/>
                                                        </div>
                                                    </div>		
                                                    <div className="col-lg-12">                               
                                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                                            <label><i className="fal fa-users"></i> Adults</label>
                                                            <select name="adults" id="adu">
                                                            <option value="sports-massage">Adults</option>
                                                            <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </select>
                                                        </div>
                                                    </div>	
                                                    <div className="col-lg-12">                               
                                                        <div className="contact-field p-relative c-option mb-20">                                   
                                                            <label><i className="fal fa-concierge-bell"></i> Room</label>
                                                            <select name="room" id="rm">
                                                            <option value="sports-massage">Room</option>
                                                            <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="slider-btn mt-15">                                          
                                                            <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s"><span>Book Table Now</span></button>				
                                                        </div>                             
                                                    </div>
                                                </div>
                                            </form>                            
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                                    <h3 className="h3-title">Email : bookings@NinYanWays.Com </h3>
                                    <Link to="javascript:void(0);" title="Call now">contact +91 89889 99951</Link>
                                </div>
                            </aside>
                        </div> */}
					    <div className="col-lg-12 col-md-12 col-sm-12 order-1">
                            <div className="service-detail">
                                <div className="two-column">
                                    <div className="row align-items-center single-room-img-container">
                                        <div className="image-column  single-room-imgs col-xl-6 col-lg-12 col-md-12">
                                        <figure className="image"><img src={rooms[id].img1} alt=""/></figure>
                                        </div>
                                        <div className="text-column single-room-imgs col-xl-3 col-lg-12 col-md-12">
                                            <figure className="image"><img src={rooms[id].img2} alt=""/></figure>
                                            <figure className="image"><img src={rooms[id].img3} alt=""/></figure>
                                        </div>     
                                        <div className="text-column single-room-imgs col-xl-3 col-lg-12 col-md-12">
                                            <figure className="image"><img src={rooms[id].img4} alt=""/></figure>
                                            <figure className="image"><img src={rooms[id].img5} alt=""/></figure>
                                        </div>                                
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="row align-items-center mb-30">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="price">
                                                <h2>{rooms[id].title}</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 single-room-stars text-right">
                                            <img src="../../assets/img/testimonial/review-icon.png" alt="img"/>
                                        </div>
                                    </div>
                                    <p>{rooms[id].description}</p>
                                    {/* <p>Interdum et malesu they adamale fames ac anteipsu pimsine faucibus curabitur arcu site feugiat in tortor in, volutpat sollicitudin libero. Hotel non lorem acer suscipit bibendum vulla facilisi nedeuter nunc volutpa mollis sapien velet conseyer turpeutionyer masin libero sempe mollis.</p>
                                    <h3>Room Feature.</h3> */}
                                    <ul className="room-features single-room-amenities d-flex align-items-center">
                                        <li>
                                        <i className="fal fa-tv-retro"></i> LCD TV
                                        </li>
                                        <li>
                                        <i className="fal fa-wifi"></i> High Speed Wifi
                                        </li>
                                        <li>
                                        <i className="fal fa-mountain"></i> Mountain View
                                        </li>

                                        {
                                            rooms[id].separate_amenity === true ? 
                                                (   <li>
                                                        <i className="fal fa-utensils"></i> Outdoor Dining
                                                    </li>   
                                                    )
                                            :
                                            (   <li>
                                                    <i className="fal fa-window-frame"></i> Private Balcony
                                                </li>   
                                                )
                                        }

                                        <li>
                                        <i className="fal fa-phone-rotary"></i> Phone
                                        </li>
                                        <li>
                                        <i className="fal fa-bed-empty"></i> Heated Mattress
                                        </li>
                                        <li>
                                        <i className="fal fa-toilet-paper"></i> Toiletries
                                        </li>
                                        <li>
                                            <i className="fal fa-briefcase-medical"></i> First Aid Kit
                                        </li>
                                    </ul>
                                    {/* <h3>Children and extra beds.</h3>
                                    <p>Children are welcome Kids stay free! Children stay free when using existing bedding; children may not be eligible for complimentary breakfast Rollaway/extra beds are available for $ 10 per day.Interdum et malesu they adamale fames ac anteipsu pimsine faucibus curabitur arcu site feugiat in tortor in, volutpat sollicitudin libero. Hotel non lorem acer suscipit bibendum vulla facilisi nedeuter nunc volutpa mollis sapien velet conseyer turpeutionyer masin libero sempe mollis.</p> */}
                                    <div className="mb-50">
                                        <a href={rooms[id].airbnbURL} className="btn ss-btn" target='_blank' rel="noopener noreferrer">Book This Room</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
  )
}

export default Main