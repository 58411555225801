import { Link } from 'react-router-dom'
import React , { useState } from 'react'
import BGImg from "../../../assets/img/bg/bdrc-bg.jpg";
import BGVImg from "../../../assets/img/bg/video-bg.png";
import FsLightbox from 'fslightbox-react';
import {motion} from 'framer-motion';
import { useParams } from 'react-router-dom';



import './NewRooms.css'
import bgVideo from '../../../assets/video/bg_landing.mp4'
import leftFeature from '../../../assets/img/features/about_1.jpg'

import Blog from "../../../assets/img/bg/an-img-06.png"
import Blog1 from "../../../assets/img/blog/property-card-1.jpeg"
import Blog2 from "../../../assets/img/blog/property-card-5.jpeg"
import Blog3 from "../../../assets/img/blog/property-card-6.jpg"
import Blog4 from "../../../assets/img/blog/property-card-7.jpg"

import boho_room from '../../../assets/img/room-img/boho/boho_3.jpg'
import himachali_room from '../../../assets/img/room-img/himachali/himachali_4.jpg'
import artDeco_room from '../../../assets/img/room-img/art-deco/art_deco_1.jpeg'
import plant_room from '../../../assets/img/room-img/plant/plant_3.jpg'
import scandinavian_room from '../../../assets/img/room-img/scandinavian/scandinavian_5.jpg'
import vintage_river_room from '../../../assets/img/room-img/vintage-river/vintage_river_1.jpg'
import mud_room from '../../../assets/img/room-img/mud/mud_1.jpg'
import rustic_room from '../../../assets/img/room-img/rustic/rustic_1.jpg'
import stone_room from '../../../assets/img/room-img/stone/stone_1.jpg'
import vintage_mountain_room from '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_1.jpg'
import scandanavian_triple_room from '../../../assets/img/room-img/scandinavian/scandinavian_2.jpg'
import vintage_triple_room from '../../../assets/img/room-img/vintage-mountain/vintage_mountaine_2.jpg'


import icon_wifi from '../../../assets/img/icon/wifi.png'
import icon_laundry from '../../../assets/img/icon/washing-machine.png'
import icon_toiletry from '../../../assets/img/icon/toiletries.png'
import icon_bathroom from '../../../assets/img/icon/bath.png'
import icon_window from '../../../assets/img/icon/window.png'
import icon_balcony from '../../../assets/img/icon/balcony.png'
import icon_mattress from '../../../assets/img/icon/mattress.png'
import icon_books from '../../../assets/img/icon/book.png'
import icon_firepit from '../../../assets/img/icon/firepit.png'
import icon_fan from '../../../assets/img/icon/fans.png'
import icon_comforter from '../../../assets/img/icon/mattress-pad.png'
import icon_common_area from '../../../assets/img/icon/chair.png'

import '../../../assets/css/overlap.css'

const Main = () => {
    const [yShow, setyShow] = useState()
    const [toggler, setToggler] = useState(false);
    
    const { id } = useParams();

    const [img, setImg] = useState()
    const [img1, setImg1] = useState()
    const [img2, setImg2] = useState()
    const [img3, setImg3] = useState()
    const [img4, setImg4] = useState()
    const [img5, setImg5] = useState()
    
    const [activeImage , setActiveImage] = useState(1)
    const [tabMenu, tabActive] = useState({all : true})

    const [images , setImages] = useState([
   
        'assets/img/gallery/room-img01.png',
        'assets/img/gallery/room-img02.png',
        'assets/img/gallery/room-img03.png',
        'assets/img/gallery/room-img04.png',
        'assets/img/gallery/room-img05.png',
        'assets/img/gallery/room-img06.png'
	])


    const galleryHeight=()=>{
        if(tabMenu.all){
            return "h1200"
        }else if(tabMenu.river){
            return "h600"
        }else {
            return "h300"
        }
    }



  return (
    <>
        <main>

            <section className="about-area room-first-sec about-p pt-120 pb-90 p-relative fix" >
                <div className="container">
                    <div className="row">
                        <div className="room-first-img-sec content-img col-lg-6 col-md-12 col-sm-12">
                            <div className="content-img-box s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src='../../../assets/img/features/about_1_full.jpg' alt="img" />   
                            </div>
                        </div>
					    <div className="content-desc col-lg-6 col-md-12 col-sm-12" style={{background: "#F7F5F1"}}>
                            <div className="about-content s-about-content  wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title">  
                                    <motion.h2 className='mb-05'  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>A Blissful Adobe</motion.h2>                                   
                                
                                    <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Nestled amidst the most inspiring Himalayan mountains, your modern-yet-minimalist ecolodge is surrounded by exceptional beauty and unique cultural charm. More than a boutique hotel, NinYanWays is a “happy place” mentality and an escape from the hustle and bustle of the city life and into the lap of spectacular vistas and vibrant natural elements, offering tailored experiences, indulgent comfort and cosy accommodations to relax, rejuvenate and reflect.</motion.p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="services" className="main-content-section services-area pt-80 pb-90">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">    
                            <div className="section-title center-align mb-30 text-center">
                                <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} >Characterized Corridors</motion.h2>
                                <motion.p initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Our selection of stylish suites with distinct themes - each of tastefully minimalist design - offer relatable refuge, perfect for a restful retreat from the ordinary.</motion.p>
                                
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="my-masonry text-center mb-10">
                                <div className="button-group filter-button-group ">
                                    <button className={`${tabMenu.all && "active"}`} onClick={()=>tabActive({all:true})} data-filter="*">All</button>
                                    <button className={`${tabMenu.river && "active"}`} onClick={()=>tabActive({river:true})} data-filter=".river">River Facing Balcony</button>
                                    <button className={`${tabMenu.mountain && "active"}`} onClick={()=>tabActive({mountain:true})} data-filter=".mountain">Mountain Facing</button>	
                                    <button className={`${tabMenu.double && "active"}`} onClick={()=>tabActive({double:true})} data-filter=".double">Double Rooms with Two Balconies</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row rooms-card">

                    {tabMenu.river &&
                        <> 
                        <div className="col-xl-4 col-md-6 river">                      
                                                    <div className="single-services ser-m mb-30">
                                                        <div className="services-thumb"  onClick={() => { 
                                                            setToggler(!toggler)
                                                            setActiveImage(1)
                                                        }}
                                                        >
                                                            <Link to="/room-details/boho" onClick={() => setImg(true)} className="gallery-link popup-image" >
                                                            <img src={boho_room} alt="img" />
                                                            </Link>
                                                        </div>
                                                        <div className="services-content room-content"> 
                                                            <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">
                                                                <div className="day-book room-book-btn text-center">
                                                                <ul>
                                                                        <li>Book This Room</li>
                                                                    </ul>
                                                                </div>
                                                            </a>
                                                            <Link to="/room-details/boho" >
                                                            <h4>Boho Room</h4>    
                                                             <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours. </p>
                                                           <div >
                                                                <ul className='list-icon'>
                                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                                </ul>
                                                            </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                         
                                                    <div className="single-services ser-m mb-30">
                                                        <div className="services-thumb"  onClick={() => { 
                                                            setToggler(!toggler)
                                                            setActiveImage(2)
                                                        }}>
                                                            <Link to="/room-details/himachali"  onClick={() => setImg1(true)} className="gallery-link popup-image" >
                                                            <img src={himachali_room} alt="img" />
                                                            </Link>
                                                        </div>
                                                        <div className="services-content room-content"> 
                                                            <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">
                                                                <div className="day-book room-book-btn text-center">
                                                                    <ul>
                                                                        <li>Book This Room</li>
                                                                    </ul>
                                                                </div>
                                                            </a>
                                                            <Link to="/room-details/himachali">
                                                            <h4>Himachali Room</h4>    
                                                             <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                            <div>
                                                                <ul className='list-icon'>
                                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                                </ul>
                                                            </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                     
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                                                        <div className="single-services ser-m mb-30">
                                                            <div className="services-thumb"  onClick={() => { 
                                                                setToggler(!toggler)
                                                                setActiveImage(3)
                                                            }}>
                                                                <Link to="/room-details/deco" onClick={() => setImg2(true)} className="gallery-link popup-image" >
                                                                <img src={artDeco_room} alt="img" />
                                                                </Link>
                                                            </div>
                                                            <div className="services-content room-content"> 
                                                                <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">
                                                                    <div className="day-book room-book-btn text-center">
                                                                    <ul>
                                                                            <li>Book This Room</li>
                                                                        </ul>
                                                                    </div>
                                                                </a>
                                                                <Link to="/room-details/deco">
                                                                <h4>Art Deco Room</h4>    
                                                                <p> Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                                <div>
                                                                    <ul className='list-icon'>
                                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                                        <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                                        <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                                        <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                                        <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                                        <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                                    </ul>
                                                                </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                        
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                        
                                                        <div className="single-services ser-m mb-30">
                                                            <div className="services-thumb"  onClick={() => { 
                                                                setToggler(!toggler)
                                                                setActiveImage(4)
                                                            }}>
                                                                <Link to="/room-details/plant" onClick={() => setImg3(true)} className="gallery-link popup-image" >
                                                                <img src={plant_room} alt="img" />
                                                                </Link>
                                                            </div>
                                                            <div className="services-content room-content"> 
                                                                <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer" align="center">
                                                                    <div className="day-book room-book-btn text-center">
                                                                    <ul>
                                                                            <li>Book This Room</li>
                                                                        </ul>
                                                                    </div>
                                                                </a>
                                                                <Link to="/room-details/plant">
                                                                    <h4>Plant Room</h4>    
                                                                    <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                                    <div>
                                                                        <ul className='list-icon'>
                                                                        <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                                        </ul>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                      
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                        
                                                    <div className="single-services ser-m mb-30">
                                                            <div className="services-thumb"  onClick={() => { 
                                                                setToggler(!toggler)
                                                                setActiveImage(5)
                                                            }}>
                                                                <Link to="/room-details/scandinavian" onClick={() => setImg4(true)} className="gallery-link popup-image">
                                                                <img src={scandinavian_room} alt="img" />
                                                                </Link>
                                                            </div>
                                                            <div className="services-content room-content"> 
                                                                    <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">
                                                                    <div className="day-book room-book-btn text-center">
                                                                    <ul>
                                                                        
                                                                            <li>Book This Room</li>
                                                                        </ul>
                                                                    </div>
                                                                </a>
                                                                <Link to="/room-details/scandinavian">
                                                                    <h4>Scandinavian Room</h4>    
                                                                    <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty. </p>
                                                                    <div >
                                                                        <ul className='list-icon'>
                                                                        <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                                        </ul>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                   
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                                                        <div className="single-services ser-m mb-30">
                                                            <div className="services-thumb"  onClick={() => { 
                                                                setToggler(!toggler)
                                                                setActiveImage(6)
                                                            }}>
                                                                <Link to="/room-details/vintage" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                                                <img src={vintage_river_room} alt="img" />
                                                                </Link>
                                                            </div>
                                                            <div className="services-content room-content"> 
                                                                <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">
                                                                    <div className="day-book room-book-btn text-center">
                                                                    <ul> 
                                                                            {/* <li>$600/Night</li> */}
                                                                            <li>Book This Room</li>
                                                                        </ul>
                                                                    </div>
                                                                </a>
                                                                <Link to="/room-details/vintage">
                                                                    <h4> Vintage Room</h4>    
                                                                    <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                                    <div>
                                                                        <ul className='list-icon'>
                                                                        <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                                            <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                                        </ul>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    
                        </div>
                        </>
                    }    

                    {tabMenu.mountain &&
                        <>
                            <div className="col-xl-4 col-md-6 mountain">
                                    <div className="single-services ser-m mb-30">
                                        <div className="services-thumb"  onClick={() => { 
                                            setToggler(!toggler)
                                            setActiveImage(6)
                                        }}>
                                            <Link to="/room-details/mud" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                            <img src={mud_room} alt="img" />
                                            </Link>
                                        </div>
                                        <div className="services-content room-content"> 
                                                <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">
                                                <div className="day-book room-book-btn text-center">
                                                <ul> 
                                                        {/* <li>$600/Night</li> */}
                                                        <li>Book This Room</li>
                                                    </ul>
                                                </div>
                                            </a>
                                            <Link to="/room-details/mud">
                                                <h4>Mud Room </h4>    
                                                <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                <div>
                                                    <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                            
                            </div>
                            <div className="col-xl-4 col-md-6 mountain">

                                    <div className="single-services ser-m mb-30">
                                        <div className="services-thumb"  onClick={() => { 
                                            setToggler(!toggler)
                                            setActiveImage(6)
                                        }}>
                                            <Link to="/room-details/rustic" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                            <img src={rustic_room} alt="img" />
                                            </Link>
                                        </div>
                                        <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">
                                                <div className="day-book room-book-btn text-center">
                                                <ul> 
                                                
                                                        <li> Book This Room </li>
                                                    </ul>
                                                </div>
                                            </a>
                                            <Link to="/room-details/rustic">
                                                <h4>Rustic Room</h4>    
                                                <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                <div>
                                                    <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                
                            </div>
                            <div className="col-xl-4 col-md-6 mountain">

                                    <div className="single-services ser-m mb-30">
                                        <div className="services-thumb"  onClick={() => { 
                                            setToggler(!toggler)
                                            setActiveImage(6)
                                        }}>
                                            <Link to="/room-details/stone" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                            <img src={stone_room} alt="img" />
                                            </Link>
                                        </div>
                                        <div className="services-content room-content"> 
                                                <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">
                                                <div className="day-book room-book-btn text-center">
                                                <ul> 
                            
                                                        <li>Book This Room</li>
                                                    </ul>
                                                </div>
                                            </a>
                                            <Link to="/room-details/stone">
                                                <h4>Stone Room</h4>    
                                                <p> The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                <div>
                                                    <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                
                            </div>
                            <div className="col-xl-4 col-md-6 mountain">
                                    <div className="single-services ser-m mb-30">
                                        <div className="services-thumb"  onClick={() => { 
                                            setToggler(!toggler)
                                            setActiveImage(6)
                                        }}>
                                            <Link to="/room-details/vintage" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                            <img src={vintage_mountain_room} alt="img" />
                                            </Link>
                                        </div>
                                        <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">
                                                <div className="day-book room-book-btn text-center">
                                                <ul> 
                                                        {/* <li>$600/Night</li> */}
                                                        <li>Book This Room</li>
                                                    </ul>
                                                </div>
                                            </a>
                                            <Link to="/room-details/vintage">
                                                <h4> Vintage Room</h4>    
                                                <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                <div>
                                                    <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                        <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                
                            </div>

                        </>
                        }

                    {tabMenu.double &&
                        <>
                            <div style={{ display: 'none'}} className="col-xl-4 col-md-6 double">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="#/room-details/scandinavian" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={scandanavian_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                                <ul> 
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/scandinavian">
                                            <h4>Scandanavian Triple</h4>    
                                            <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'none'}} className="col-xl-4 col-md-6 double">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/mountain_vintage" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={vintage_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                                                    {/* <li>$600/Night</li> */}
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/mountain_vintage">
                                            <h4>Vintage Triple</h4>    
                                            <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/* Coming soon room card */}
                            <div className="col-xl-4 col-md-6 double coming-soon-card">
                                <div className='coming-soon-text'>
                                    <h2>Coming Soon...</h2>
                                </div>
                                <div className="coming-soon-content single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="#" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={scandanavian_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='#' target='_blank' rel="noopener noreferrer">
                                        </a>
                                        <Link to="#">
                                            <h4>Scandanavian Triple</h4>    
                                            <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 double coming-soon-card">
                                <div className='coming-soon-text'>
                                    <h2>Coming Soon...</h2>
                                </div>
                                <div className="coming-soon-content single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="#" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={vintage_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <Link to="#">
                                            <h4>Vintage Triple</h4>    
                                            <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </>
                    }

                    {tabMenu.all &&
                        <> 
                        <div className="col-xl-4 col-md-6 river">                      
                            <div className="single-services ser-m mb-30">
                                <div className="services-thumb"  onClick={() => { 
                                    setToggler(!toggler)
                                    setActiveImage(1)
                                }}
                                >
									<Link to="/room-details/boho" onClick={() => setImg(true)} className="gallery-link popup-image" >
                                    <img src={boho_room} alt="img" />
									</Link>
                                </div>
                                <div className="services-content room-content"> 
                                    <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">
                                        <div className="day-book room-book-btn text-center">
                                        <ul>
                                                <li>Book This Room</li>
                                            </ul>
                                        </div>
                                    </a>
                                    <Link to="/room-details/boho" >
                                    <h4>Boho Room</h4>    
                                     <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours. </p>
                                   <div >
                                        <ul className='list-icon'>
                                            <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                        </ul>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 river">
 
                            <div className="single-services ser-m mb-30">
                                <div className="services-thumb"  onClick={() => { 
                                    setToggler(!toggler)
                                    setActiveImage(2)
                                }}>
									<Link to="/room-details/himachali"  onClick={() => setImg1(true)} className="gallery-link popup-image" >
                                    <img src={himachali_room} alt="img" />
									</Link>
                                </div>
                                <div className="services-content room-content"> 
                                    <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">
                                        <div className="day-book room-book-btn text-center">
                                            <ul>
                                                <li>Book This Room</li>
                                            </ul>
                                        </div>
                                    </a>
                                    <Link to="/room-details/himachali">
                                    <h4>Himachali Room</h4>    
                                     <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                    <div>
                                        <ul className='list-icon'>
                                        <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                            <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                        </ul>
                                    </div>
                                    </Link>
                                </div>
                            </div>
             
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(3)
                                    }}>
                                        <Link to="/room-details/deco" onClick={() => setImg2(true)} className="gallery-link popup-image" >
                                        <img src={artDeco_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul>
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/deco">
                                        <h4>Art Deco Room</h4>    
                                        <p> Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                        <div>
                                            <ul className='list-icon'>
                                            <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                            </ul>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                
                        </div>
                        <div className="col-xl-4 col-md-6 river">

                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(4)
                                    }}>
                                        <Link to="/room-details/plant" onClick={() => setImg3(true)} className="gallery-link popup-image" >
                                        <img src={plant_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer" align="center">
                                            <div className="day-book room-book-btn text-center">
                                            <ul>
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/plant">
                                            <h4>Plant Room</h4>    
                                            <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
              
                        </div>
                        <div className="col-xl-4 col-md-6 river">

                            <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(5)
                                    }}>
                                        <Link to="/room-details/scandinavian" onClick={() => setImg4(true)} className="gallery-link popup-image">
                                        <img src={scandinavian_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul>
                                                
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/scandinavian">
                                            <h4>Scandinavian Room</h4>    
                                            <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty. </p>
                                            <div >
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                           
                        </div>
                        <div className="col-xl-4 col-md-6 river">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/river_vintage" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={vintage_river_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                                                    {/* <li>$600/Night</li> */}
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/river_vintage">
                                            <h4> Vintage Room</h4>    
                                            <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            
                        </div>

                      
                        <div className="col-xl-4 col-md-6 mountain">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/mud" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={mud_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                                                    {/* <li>$600/Night</li> */}
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/mud">
                                            <h4>Mud Room </h4>    
                                            <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                           
                        </div>
                        <div className="col-xl-4 col-md-6 mountain">

                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/rustic" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={rustic_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                                            
                                                    <li> Book This Room </li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/rustic">
                                            <h4>Rustic Room</h4>    
                                            <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            
                        </div>
                        <div className="col-xl-4 col-md-6 mountain">

                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/stone" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={stone_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                        
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/stone">
                                            <h4>Stone Room</h4>    
                                            <p> The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            
                        </div>
                        <div className="col-xl-4 col-md-6 mountain">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/mountain_vintage" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={vintage_mountain_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                                                    {/* <li>$600/Night</li> */}
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/mountain_vintage">
                                            <h4> Vintage Room</h4>    
                                            <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.
                                            </p>
                                            <div>
                                                <ul className='list-icon'>
                                                <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            
                        </div>

                 
                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 double">
    
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="#/room-details/scandinavian" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={scandanavian_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                                <ul> 
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/scandinavian">
                                            <h4>Scandanavian Triple</h4>    
                                            <p>The Scandanavian triple room was designed on similar lines as our Scandinavian room.It is slightly bigger with a provision of additional bedding.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                     
                        </div>

                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 double">

                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="/room-details/mountain_vintage" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={vintage_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">
                                            <div className="day-book room-book-btn text-center">
                                            <ul> 
                                                    {/* <li>$600/Night</li> */}
                                                    <li>Book This Room</li>
                                                </ul>
                                            </div>
                                        </a>
                                        <Link to="/room-details/mountain_vintage">
                                            <h4>Vintage Triple</h4>    
                                            <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                           
                        </div>

                            {/* <div className="col-xl-4 col-md-6 double coming-soon-card">
                                <div className='coming-soon-text'>
                                    <h2>Coming Soon...</h2>
                                </div>
                                <div className="coming-soon-content single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="#" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={scandanavian_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                            <a href='#' target='_blank' rel="noopener noreferrer">
                                        </a>
                                        <Link to="#">
                                            <h4>Scandanavian Triple</h4>    
                                            <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 double coming-soon-card">
                                <div className='coming-soon-text'>
                                    <h2>Coming Soon...</h2>
                                </div>
                                <div className="coming-soon-content single-services ser-m mb-30">
                                    <div className="services-thumb"  onClick={() => { 
                                        setToggler(!toggler)
                                        setActiveImage(6)
                                    }}>
                                        <Link to="#" onClick={() => setImg5(true)} className="gallery-link popup-image" >
                                        <img src={vintage_triple_room} alt="img" />
                                        </Link>
                                    </div>
                                    <div className="services-content room-content"> 
                                        <Link to="#">
                                            <h4>Vintage Triple</h4>    
                                            <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                            <div>
                                                <ul className='list-icon'>
                                                    <li><img src="../../../assets/img/icon/sve-icon1.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon2.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/cctv-camera.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon4.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/sve-icon5.png" alt="img" /></li>
                                                    <li><img src="../../../assets/img/icon/first-aid-kit.png" alt="img" /></li>
                                                </ul>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                        
                        </>
                        }
                        
                    </div>
                </div>
            </section>

            <section id="faq" className="main-content-section faq-area pt-120 pb-110" style={{background: "#f7f5f1"}}>             
                <div className="container"> 
                <div className="row justify-content-center">
                        <div className="col-xl-12">    
                            <div className="section-title center-align mb-50 text-center">
                                <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} style={{color: '#000'}} >Policies</motion.h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                                    Safety & Accessibility  
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="card-body" style={{ textAlign: 'justify'}}>
                                            
                                            <p><b>AMBULATION</b></p>
                                            <p>The property is on a height from the road & has no elevators (50 steps).</p> 

                                            <p><b>TIMING</b></p>
                                            <p>All doors in and out of the building are locked between 11 and 12 at night and remain locked until 8 in the morning.</p>

                                            <p><b>OPERATIONAL HOURS</b></p>
                                            <p>Monday - Sunday<br></br>
                                            8 AM - 11 PM</p>

                                            <p><b>BELONGINGS</b></p>
                                            <p>Guests are encouraged to keep their valuables safe. As each room can be locked from inside and outside,the hotel shall not be liable for the loss of money or other personal valuables during your stay.</p>

                                            <p><b>PROHIBITED ITEMS</b></p>
                                            <p>The following items are not permitted in the Hotel premises: firearms or other weapons, flammable, explosive or otherwise hazardous materials and objects emitting a foul odour.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="faq-btn" type="button" data-bs-toggle="collapse"  data-bs-target="#collapseTwo">
                                                    Check-in & Check-out
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="card-body" style={{ textAlign: 'justify'}}>

                                            <p><b>Requirements</b></p>
                                            <p>Guests must be 18 years or older with photo identification to check in and guests under the age of 18 must be accompanied by an adult. For security purposes, all Indian guests are required to present a valid domestic form of photo ID and foreign guests are required to present a valid international ID.</p> 
                                                                                         
                                            <p><b>Early Check-in / Late Check-out</b></p>
                                            <p>Unless otherwise stated in the specific rate plan terms and conditions, check-in time is after 1pm and check-out time is 11am.</p> 

                                            <p>For a confirmed early check-in before 1pm, it is recommended to reserve the preceding night as the booking includes the following morning. <br></br> However, if the room happens to be free when you arrive, we'll happily facilitate the early check-in at no extra cost.</p>

                                            <p>Subject to room availability, the hotel may accommodate late check-out until 12pm for an additional fee of 50% of the total tariff.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                                    General
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                            <div className="card-body" style={{ textAlign: 'justify'}}>

                                            <p>Smoking inside the room or enclosed spaces is strictly prohibited. We levy a hefty fine of INR 10,000 to discourage the same.</p>

                                            <p>Eating in bed is not appreciated; stains on the linen, towels, etc. from food, lipstick or any other sources will be considered as negligent damage and guests will be fined to cover replacement costs.</p>

                                            <p>Kindly close and lock the windows & balcony doors before leaving the room.</p>

                                            <p>Indoor furniture and linens should not be used on the balcony.</p>
                                            
                                            <p>No visitors are allowed inside the room. Garden seating area is also limited to guest usage.</p>

                                            <p>No music and/or loud chatter is allowed after 11pm.</p>

                                            <p>Kindly be polite towards staff and other guests according to common courtesy.</p>

                                            <p>The kettle in each room should only be used for heating drinking water.</p>
                                            <p>Use of cooking appliances of all kinds is not permitted inside.</p>
                                            <p>Please don't dry wet clothes on the balcony railing, or leave wet towels on furniture. There is a dedicated stand on the balcony for drying clothes and you can just let us know if you run out of space.</p>
                                            <p>Third-party laundry service is provided on request and housekeeping service is provided at a nominal charge of 99 (INR).</p>
                                            <p>Hotel property (towels, quilts, kettle, etc.) are not allowed to be taken beyond the hotel premises.</p>
                                            <p>We would by all means appreciate your support in helping us keep the property clean by using the bins and the ashtrays conveniently available.</p>
                                            

                                            <p><b>DAMAGE TO HOTEL PROPERTY</b></p>
                                            <p>The hotel reserves the right to charge guests the cost of rectifying damage to the interior or property, caused by deliberate or negligent behaviour.</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card">
                                        <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/terms' target='_blank' rel="noopener noreferrer">
                                            <div className="razorpay-card card-header" id="headingFour">
                                                <h2 className="mb-0">
                                                    <button className="faq-btn collapsed" >
                                                    Terms & Conditions
                                                    </button>
                                                </h2>
                                            </div>
                                        </a>
                                    </div>
                                    
                                    <div className="card">
                                        <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/refund' target='_blank' rel="noopener noreferrer">
                                            <div className="razorpay-card card-header" id="headingFour">
                                                <h2 className="mb-0">
                                                    <button className="faq-btn collapsed" >
                                                    Cancellation & Refund Policy
                                                    </button>
                                                </h2>
                                            </div>
                                        </a>
                                    </div>
                                    
                                    <div className="card">
                                        <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/privacy' target='_blank' rel="noopener noreferrer">
                                            <div className="razorpay-card card-header" id="headingFour">
                                                <h2 className="mb-0">
                                                    <button className="faq-btn collapsed" >
                                                    Privacy Policy
                                                    </button>
                                                </h2>
                                            </div>
                                        </a>
                                    </div>
                                    
                                    <div className="card">
                                        <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/shipping' target='_blank' rel="noopener noreferrer">
                                            <div className="razorpay-card card-header" id="headingFour">
                                                <h2 className="mb-0">
                                                    <button className="faq-btn collapsed" >
                                                    Shipping & Delivery Policy
                                                    </button>
                                                </h2>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="amneties" className="main-content-section blog-area p-relative fix pt-120 pb-120">
                 <div className="animations-02"><img src="../../../assets/img/bg/mountain.png" style={{opacity: '0.1'}} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row align-items-center"> 
                        <motion.div className="col-xl-12" initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>    
                            <div className="section-title center-align mb-40 text-center">
                                <h2>Amenities</h2>
                            </div>
                        </motion.div>
                        <div className="col-lg-12">

                            <div className="amneties-section-container section-title center-align text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <div className='amneties-grp'>
                                    <div className='amenities-left-section' >

                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_wifi} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Wireless internet</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_laundry} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>On-demand laundry</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_toiletry} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Toiletries and fresh towels</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_bathroom} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Private ensuite bathrooms</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_window} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Large windows for natural light </h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_common_area} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Comfortable common areas</h6></div>
                                        </div>
                                        
                                    </div>

                                    <div className='amenities-right-section'>

                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_comforter} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Down comforters</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_books} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Board games & books</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_firepit} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Outdoor fireplace & fire pit</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_fan} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Pedestal fans for warm months</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_mattress} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Linens and heated mattress pads</h6></div>
                                        </div>
                                        <div className='amenities-single'>
                                            <div className='amenities-single-img'><img src={icon_balcony} alt="img" /></div>
                                            <div className='amenities-single-text'><h6>Balcony with river and pine forest view</h6></div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section> 

            <section id="property-card" className="room-property-card-sec main-content-section blog-area p-relative fix pt-120 pb-90" style={{background: "#f7f5f1"}}>
                <div className="container">
                    <div className="property-grp-cards row">
                       <div className="property-card">
                            <div className="single-post2 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog1} alt="img"/>
                                </div>                    
                                <div className="blog-content2">    
                                    <h4>Conceptual Corridors</h4>  
                                    <p>From the Boho Bedroom to the Scandinavian Suite, each room was designed with a theme inspired by Himachal and the Himalayas.</p>
                                 <br></br>
                        
                                </div>
                            </div>
                        </div>
                         <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog2} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Smart Service</h4>  
                                    <p>Most suites have a smart TV and an intercom, but room service can also be ordered with the click of a button.
                                    <br></br>
                                    <br></br>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog3} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4 style={{marginBottom: '20px'}}>Renewable Responsibilities</h4>   
                                    <p>We practice ecological stewardship that includes food waste prevention, rubbish reduction, recycling and safe disposal.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog4} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Ethereal Elements</h4>   
                                    <p>Situated at the confluence of two rivers, and surrounded by apple trees, the property is also overlooked by multiple mountainpeaks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>

        <FsLightbox
            toggler={toggler}
            sources={images}
            slide={activeImage}
        />

        {yShow &&
        <>
            <div className="mfp-bg mfp-ready"></div>
            <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex="-1" style={{overflow: "hidden"}}>
                <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                    <div className="mfp-content">
                        <div className="mfp-iframe-scaler">
                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setyShow(false)}>×</button>
                            <iframe className="mfp-iframe" src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1" frameborder="0" allowFullScreen=""></iframe>
                        </div>
                    </div>
                    <div className="mfp-preloader">Loading...</div>
                </div>
            </div>
        </>
        }
        
    </>
  )
}

export default Main