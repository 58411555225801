import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'

import Navbar from '../component/Navbar/NavNew'
import Footer from '../component/Footer/NewFooter'
import Home from '../component/Home/Main'
import Home2 from '../component/Home2/Main'
import Home3 from '../component/Home3/Main'
import Home4 from '../component/Home4/Main'
import About from '../component/About/Main'
import OurRoom from '../component/Rooms/OurRoom/NewRooms'
// import OurRoom from '../component/Rooms/OurRoom/RoomsModified'
import RoomDetails from '../component/Rooms/RoomDetails/Main'
import Service from '../component/Facilities/Service/Main'
import ServiceDetails from '../component/Facilities/ServiceDetails/Main'
// import Gallery from '../component/Pages/Gallary/Main'
import Gallery from '../component/Pages/Gallary/NewGallary'
import Faq from '../component/Faq/Main'
import Team from '../component/Pages/Team/Main'
import TeamDetails from '../component/Pages/TeamDetails/Main'
import Pricing from '../component/Pages/Pricing/Main'
import Shop from '../component/Pages/Shop/Main'
import ShopDetails from '../component/Pages/ShopDetails/Main'
import Blog from '../component/Blog/BlogMain/Main'
import BlogDetails from '../component/Blog/BlogDetails/Main'
import Contact from '../component/Contact/Main'
import Restaurant from '../component/Restaurant';
import Location from '../component/location/location';
import Season from '../component/Seasons/Main'
import Review from '../component/Reviews/Main'


const Routing = () => {
    const [homepage, sethomepage] = useState(false)
    const [height, setHeight] = useState(window.innerHeight)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/home-03" || location.pathname === "/home-04"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])
    
    const [footerpage, setfooterpage] = useState(false)
    useEffect(() => {
      if (location.pathname === "/home-03"){
        setfooterpage(false)
      }else{
        setfooterpage(true)
      }
    }, [location])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const setFooter = () => {
      setHeight(window.innerHeight)
      if(window.innerWidth > 1200 && height > 600){
        let top = height - 570;
        let footer = $('footer');
        footer.css('top', `${top}px`)
      }
    }

    const staticFooter = () => {
      if(window.innerWidth > 1200 &&  height > 600){
        let top = height - 570;
        let footer = $('footer');
        footer.css('top', `${top}px`)
      }
    }

    useEffect(() => {
      window.addEventListener("load", staticFooter);
      return () => window.removeEventListener("load", staticFooter)
    }, [])

    
  
    useEffect(() => {
      window.addEventListener("resize", setFooter);
      return () => window.removeEventListener("resize", setFooter)
    }, [height])

    return (
        <>
            {homepage && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/home-02" element={<Home2 />} />
                <Route path="/home-03" element={<Home3 />} />
                <Route path="/home-04" element={<Home4 />} /> */}
                <Route path="/about" element={<About />} />
                <Route path="/rooms" element={<OurRoom />} />
                <Route path="/dining"  element={<Restaurant/>}/>
                <Route path="/room-details/:id" element={<RoomDetails />} />
                {/* <Route path="/service" element={<Service />} /> */}
                {/* <Route path="/service-details" element={<ServiceDetails />} /> */}
                <Route path="/gallery" element={<Gallery />} />
                {/* <Route path="/faq" element={<Faq />} /> */}
                {/* <Route path="/team" element={<Team />} /> */}
                {/* <Route path="/team-details" element={<TeamDetails />} /> */}
                {/* <Route path="/pricing" element={<Pricing />} /> */}
                {/* <Route path="/shop" element={<Shop />} /> */}
                {/* <Route path="/shop-details" element={<ShopDetails />} /> */}
                {/* <Route path="/blog" element={<Blog />} /> */}
                {/* <Route path="/blog-details" element={<BlogDetails />} /> */}
                {/* <Route path="/contact" element={<Contact />} /> */}
                <Route path="/location" element={<Location/>}/>
                <Route path="/season" element={<Season/>}/>
                <Route path="/reviews" element={<Review/>}/>

                <Route path="*" element={<Navigate to="/" />} />

              
            </Routes>
            {footerpage && <Footer />}
        </>
    )
}

export default Routing