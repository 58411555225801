import { Link, useLocation } from 'react-router-dom'
import React , { useState } from 'react'
import FsLightbox from 'fslightbox-react';
import ReactPlayer from 'react-player/lazy'
import {motion} from 'framer-motion';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import '../Rooms/OurRoom/NewRooms.css'
import bgVideo from '../../assets/video/bg_landing.mp4'
import About from "../../assets/img/bg/an-img-02.png"
import videoLeft from '../../assets/video/videoLeft.mp4'

import BGImg from "../../assets/img/bg/season-bg-img.jpg";
import Resort from "../../assets/img/bg/an-img-02.png"
import Resort1 from "../../assets/img/features/feature.png"
import spring from "../../assets/img/season/season-img-8.jpg"
import summer from "../../assets/img/season/season-img-7.jpg"
import autum from "../../assets/img/season/season-img-9.jpg"
import winter from "../../assets/img/season/season-img-6.png"


const Main = () => {
    const  urlData  = useLocation();

    return(
        <>
            <main>
            <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${BGImg})`}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>Season</h2>    
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Season</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <BrowserView>
                <section id="res-sec5" className="about-area about-p pt-90 pb-90 p-relative fix" >
                    <div className="container">
                        <div id='spring'  className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align mb-50 text-center">
                                    <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Seasonal Advantages</motion.h2>
                                    <motion.p initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Our selection of stylish suites with distinct themes - each of tastefully minimalist design - offer relatable refuge, perfect for a restful retreat from the ordinary.</motion.p>
                                </div>
                            </div>
                        </div>
                        <div  className="row justify-content-center align-items-center mb-40">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={spring} alt="img"/>   
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px'}}>Spring (April to June)</h3>                                  
                                    </div>
                                    <p>Spring is the peak tourist season in Manali and Himachal Pradesh. The weather is pleasant, and the snow starts to melt, making it the perfect time for outdoor activities such as trekking, paragliding, camping, and river rafting. The lush greenery and blooming flowers also make it a great time to explore the nature trails and wildlife sanctuaries in the region.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Rafting and kayaking in the Beas River</li>
                                                    <li> Paragliding and ziplining in Solang Valley</li>     
                                                    <li> Hiking and camping in the Great Himalayan National Park</li>
                                                    <li> Exploring apple orchards in Kullu and Manali</li>
                                                    <li> Visiting ancient temples like the Hadimba Temple and Bijli Mahadev Temple</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div  className="row justify-content-center align-items-center mb-40">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px'}}>Monsoon (July to September)</h3>                                  
                                    </div>
                                    <p>Monsoon is the off-season in Manali and Himachal Pradesh due to the heavy rainfall and landslides that make transportation difficult. However, the lush greenery and the misty mountains make it a great time for nature lovers to enjoy the scenic beauty. The waterfalls are at their peak during this time, and the lower altitude treks are also accessible.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Trekking and camping in the Himalayas</li>
                                                    <li> Mountain biking and cycling in the lower hills</li>     
                                                    <li> Visiting hill stations like Shimla, Dalhousie, and McLeod Ganj</li>
                                                    <li> River crossing and rappelling in the Solang Valley</li>
                                                    <li> Wildlife safaris in the Great Himalayan National Park</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='summer' className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={summer} alt="img"/>   
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center align-items-center mb-40">
                            <div id='fall' className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={autum} alt="img"/>   
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px'}}>Autumn (October to November)</h3>                                  
                                    </div>
                                    <p>Autumn is a great time to visit Manali and Himachal Pradesh as the weather is pleasant, and the trees turn golden and orange, providing breathtaking views of the landscape. The weather is ideal for outdoor activities, and tourists can enjoy trekking, mountain biking, and sightseeing without the crowds.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Exploring the scenic beauty of the Kullu Valley</li>
                                                    <li> Visiting the Spiti Valley for its stunning landscapes</li>     
                                                    <li> Attending the Kullu Dussehra festival</li>
                                                    <li> Mountain climbing and rock climbing in the Pir Panjal Range</li>
                                                    <li> Enjoying the picturesque views of the Himalayan mountain range</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center align-items-center mb-40">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px'}}>Winter (December to March)</h3>                                   
                                    </div>
                                    <p>Winter is arguably the best time to experience snowfall and skiing in Manali and Himachal Pradesh. The region is covered with a blanket of snow, and tourists can enjoy winter sports like skiing, snowboarding, and ice-skating. The winter festivals like Himachal Winter Carnival and Ice Skating Carnival add to the festive atmosphere.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Skiing and snowboarding in Solang Valley and Rohtang Pass</li>
                                                    <li> Ice skating in Manali and Shimla</li>     
                                                    <li> Visiting hot springs in Manikaran and Vashisht</li>
                                                    <li> Winter trekking in the Parvati Valley</li>
                                                    <li> Attending winter festivals like the Himachal Winter Carnival in Manali</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='winter' className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={winter} alt="img"/>   
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align text-center">
                                    <p>Overall, Manali and Himachal Pradesh offer unique experiences in every season, making it a year-round destination for tourists.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BrowserView>

            <MobileView>
                <section id="res-sec5" className="about-area about-p pt-90 pb-90 p-relative fix" >
                    <div className="container">
                        <div id='spring'  className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align mb-50 text-center">
                                    <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Seasonal Advantages</motion.h2>
                                    <motion.p initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Our selection of stylish suites with distinct themes - each of tastefully minimalist design - offer relatable refuge, perfect for a restful retreat from the ordinary.</motion.p>
                                </div>
                            </div>
                        </div>
                        <div  className="row justify-content-center align-items-center mb-40">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={spring} alt="img"/>   
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px', color: '#426d49'}}>Spring (April to June)</h3>                                  
                                    </div>
                                    <p>Spring is the peak tourist season in Manali and Himachal Pradesh. The weather is pleasant, and the snow starts to melt, making it the perfect time for outdoor activities such as trekking, paragliding, camping, and river rafting. The lush greenery and blooming flowers also make it a great time to explore the nature trails and wildlife sanctuaries in the region.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Rafting and kayaking in the Beas River</li>
                                                    <li> Paragliding and ziplining in Solang Valley</li>     
                                                    <li> Hiking and camping in the Great Himalayan National Park</li>
                                                    <li> Exploring apple orchards in Kullu and Manali</li>
                                                    <li> Visiting ancient temples like the Hadimba Temple and Bijli Mahadev Temple</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div  className="row justify-content-center align-items-center mb-40">
                            <div id='summer' className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={summer} alt="img"/>   
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px', color: '#426d49'}}>Monsoon (July to September)</h3>                                  
                                    </div>
                                    <p>Monsoon is the off-season in Manali and Himachal Pradesh due to the heavy rainfall and landslides that make transportation difficult. However, the lush greenery and the misty mountains make it a great time for nature lovers to enjoy the scenic beauty. The waterfalls are at their peak during this time, and the lower altitude treks are also accessible.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Trekking and camping in the Himalayas</li>
                                                    <li> Mountain biking and cycling in the lower hills</li>     
                                                    <li> Visiting hill stations like Shimla, Dalhousie, and McLeod Ganj</li>
                                                    <li> River crossing and rappelling in the Solang Valley</li>
                                                    <li> Wildlife safaris in the Great Himalayan National Park</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center align-items-center mb-40">
                            <div id='fall' className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={autum} alt="img"/>   
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px', color: '#426d49'}}>Autumn (October to November)</h3>                                  
                                    </div>
                                    <p>Autumn is a great time to visit Manali and Himachal Pradesh as the weather is pleasant, and the trees turn golden and orange, providing breathtaking views of the landscape. The weather is ideal for outdoor activities, and tourists can enjoy trekking, mountain biking, and sightseeing without the crowds.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Exploring the scenic beauty of the Kullu Valley</li>
                                                    <li> Visiting the Spiti Valley for its stunning landscapes</li>     
                                                    <li> Attending the Kullu Dussehra festival</li>
                                                    <li> Mountain climbing and rock climbing in the Pir Panjal Range</li>
                                                    <li> Enjoying the picturesque views of the Himalayan mountain range</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center align-items-center mb-40">
                            <div id='winter' className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={winter} alt="img"/>   
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <h3 style={{ fontSize: '32px', color: '#426d49'}}>Winter (December to March)</h3>                                   
                                    </div>
                                    <p>Winter is arguably the best time to experience snowfall and skiing in Manali and Himachal Pradesh. The region is covered with a blanket of snow, and tourists can enjoy winter sports like skiing, snowboarding, and ice-skating. The winter festivals like Himachal Winter Carnival and Ice Skating Carnival add to the festive atmosphere.</p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <ul className="green mb-30">                                              
                                                    <li> Skiing and snowboarding in Solang Valley and Rohtang Pass</li>
                                                    <li> Ice skating in Manali and Shimla</li>     
                                                    <li> Visiting hot springs in Manikaran and Vashisht</li>
                                                    <li> Winter trekking in the Parvati Valley</li>
                                                    <li> Attending winter festivals like the Himachal Winter Carnival in Manali</li>
                                                </ul>
                                            </div>                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align text-center">
                                    <p>Overall, Manali and Himachal Pradesh offer unique experiences in every season, making it a year-round destination for tourists.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MobileView>

                <section className="about-area about-p pt-120 pb-90 p-relative fix" style={{background: "#f7f5f1"}}>
                    <div className="animations-02"><img src={About} alt="contact-bg-an-02"/></div>
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <motion.div className="s-about-img p-relative  wow fadeInLeft animated"   >
                                    <div className="left-video-block">
                                        <ReactPlayer url={videoLeft} loop={true} muted={true} playing={true} id='video-bg' width='100%' height='100%' />
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">  
                                        <motion.h2  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Your home away from home...</motion.h2>                                   
                                    </div>
                                    <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>We’ve all found ourselves feeling that we need a vacation from our vacation, which is why sightseeing ambitions should be balanced with our self-care agenda. So, while adventures are great, elevated accommodations are essential.</motion.p>
                                        <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>NinYan is a Hindi word for ninety-nine. NinYanWays is thus a twisted way to say ninety-nine ways. “To bliss out” has been added as the tagline to complete the meaning, making it clear that our main aim is to bring everything you could ask for while you’re on a holiday - 99 activities included - even if your travel plans happen to be contained to the art of stoic leisure.</motion.p>
                                        <div className="about-content3 mt-30">
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <div className="slider-btn" style={{}}>                                          
                                                    <Link to="/about" className="btn ss-btn smoth-scroll">About NinYanWays</Link>				
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}


export default Main