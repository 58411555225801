import { Link, useLocation } from 'react-router-dom'
import React , { useState } from 'react'
import FsLightbox from 'fslightbox-react';
import {motion} from 'framer-motion';

import './Main.css'
import BGImg from "../../assets/img/bg/about-landing-bg.jpg";


const Main = () => {
    return(
        <>
            <main>
                <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${BGImg})`}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-center">
                                    <div className="breadcrumb-title">
                                        <h2>About</h2>    
                                        <div className="breadcrumb-wrap">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">About</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about-tests" className="blog-area p-relative fix pt-90 pb-90">
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-12">
                            <div className="section-title center-align text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                 {/* <h5>Our Blog</h5> */}
                                <h2>
                                    All Reviews
                                </h2>

                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-lg-8 col-sm-12'>
                            <div className='review-cards mt-20 mb-20'>
                                <div className='mt-10'>
                                    <h3>Adhideb </h3>
                                </div>
                                <div className='mt-30'>
                                    <h5>We had a great time at Ankit's place</h5>
                                </div>
                                <div className='mt-10 mb-10'>
                                    <p>We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!
                                    </p>
                                </div>
                                <div>
                                    <h6>(May 2023)</h6>
                                </div>
                            </div>

                            <div className='review-cards mt-20 mb-20'>
                                <div className='mt-10'>
                                    <h3>Vijay </h3>
                                </div>
                                <div className='mt-30'>
                                    <h5>NinYanWays is like a blissful retreat</h5>
                                </div>
                                <div className='mt-10 mb-10'>
                                    <p>NinYanWays is like a blissful retreat. Right at the moment you find this place on Airbnb or any other platform, you get the feeling of comfort, luxury and calmness. Ankit (the super duper host) is like a guide of your visit to Manali. It was very easy to reach the place and upon arrival, got served a hot natural tea. The baggage was sent to rooms with the helper brothers at the place. Food served here is super delicious During the whole stay, Ankit and the staff took care of us like a friend. It was raining so the place offered Umbrellas, the place had everything one needs, got extra blanket, room warmers, these small gestures from the team made our stay very comfortable. Conversations with Ankit were always amazing. Made a good friend in Manali. Thank you Ankit and team for taking care of us so well. Will return soon in another season to experience the great hospitality.
                                    </p>
                                </div>
                                <div>
                                    <h6>(April 2023)</h6>
                                </div>
                            </div>

                            <div className='review-cards mt-20 mb-20'>
                                <div className='mt-10'>
                                    <h3>Tushar </h3>
                                </div>
                                <div className='mt-30'>
                                    <h5>We had an amazing stay at this place</h5>
                                </div>
                                <div className='mt-10 mb-10'>
                                    <p>We had an amazing stay at this place.The host Ankit is a super chill guy, just like the pleasant weather here. The room itself was cozy, clean, and beautifully designed with all the amenities we needed for a comfortable stay with a view of beautiful river. In the evenings, Ankit would play music & with the sound of the river in the background, creating a wonderfully relaxing ambience. The food was absolutely delicious too! The location was absolutely perfect, with easy access to public transportation, nearby attractions and old Manali market which had some amazing Cafés at walking distance. We enjoyed every moment of our stay and would highly recommend this place to anyone looking for a peaceful and comfortable stay. We are already planning our next visit here and can't wait to be back.
                                    </p>
                                </div>
                                <div>
                                    <h6>(April 2023)</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </main>
        </>
    )
}


export default Main;