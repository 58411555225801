import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BGImg from "../../assets/img/bg/footer-bg.png";
import aggrement from "../../assets/img/icon/policy-agreement.png";
import privacy from "../../assets/img/icon/policy-privacy.png";
import refund from "../../assets/img/icon/policy-refund.png";
import shipped from "../../assets/img/icon/policy-shipped.png";

import './NewFooter.css';

const NewFooter = () => {

    const [isMsgVisible , setIsMsgVisible ] = useState(false);

    let url = process.env.REACT_APP_API_URL;

    const [formData, setFormData ] = useState({
        firstn: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const formSubmitHandler = async (event) => {
        event.preventDefault();

        // const formSubmitURL = 'http://3.108.39.212/send-email';
        const formSubmitURL = `${url}`;

        console.log(formData)
        
        try {

            let data = JSON.stringify(formData)

            let response = await fetch(formSubmitURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data
            })

            if(response.ok){
                setIsMsgVisible(true);

                setFormData({
                    firstn: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                })

                setTimeout(() => {
                    setIsMsgVisible(false);
                    console.log('Form submitted successfully')
                }, 3000);
                
            }else{
                console.log('Form submission faild')
            }
            
        } catch (error) {
            console.log('error = ',error)
        }
    }
    
    const handleInput = (event) => {
        const {name, value } = event.target;
        setFormData({...formData, [name]: value})
    }

  return (
    <>
        <footer className="footer-bg footer-p">
            <div className="footer-top  pt-90 pb-40" style={{background_color: "#644222", backgroundImage: `url(${BGImg})`}}>
                <div className="container">
                    <div className="row justify-content-between">                          
                        <div className="col-xl-3 col-lg-4 col-sm-4">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title mb-20">
                                   <img src="../../assets/img/logo/ninwan_logo.png" alt="img" />
                                </div>
                                <div className="f-contact footer-contact-list">
                                    <ul>
                                        <li className='footer-single-contact-list'>
                                            <div>
                                                <i className="icon fal fa-phone"></i>
                                            </div>
                                            <div className='footer-single-contact-list-content'>
                                                <p>Call Us</p>
                                                <p>+91 89889 99951</p>
                                            </div>
                                           
                                        </li>
                                        <li className='footer-single-contact-list'>
                                            <div>
                                                <i className="icon fal fa-envelope"></i>
                                            </div>
                                            <div className='footer-single-contact-list-content'>
                                                <p>Mail Us</p>
                                                <p>ninyanways@gmail.com</p>
                                            </div>
                                        </li>
                                        <li className='footer-single-contact-list'>
                                            <div>
                                                <i className="icon fal fa-map-marker-check"></i>
                                            </div>
                                            <div className='footer-single-contact-list-content'>
                                                <p>NinYanWays, Old Manali,</p>
                                                <p>Manali, Himachal Pradesh </p>
                                                <p>175131</p>
                                            </div>
                        
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='footer-policy-container mb-20' style={{ display: 'none'}}>
                                <div className='footer-policy-img-box'>
                                    <a href="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/terms" target='_blank' rel="noopener noreferrer">
                                        <img src={aggrement} alt='img'/>
                                    </a>  
                                </div>
                                <div className='footer-policy-img-box'>
                                    <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/refund' target='_blank' rel="noopener noreferrer">
                                        <img src={refund} alt='img'/>
                                    </a>  
                                </div>
                                <div className='footer-policy-img-box'>
                                    <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/privacy' target='_blank' rel="noopener noreferrer">
                                        <img src={privacy} alt='img'/>
                                    </a>  
                                </div>
                                <div className='footer-policy-img-box'>
                                    <a href='https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/shipping' target='_blank' rel="noopener noreferrer">
                                        <img src={shipped} alt='img'/>
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-4">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2 style={{ paddingTop: '10px'}}>CONSUMER POLICY</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li><Link to="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/terms" target='_blank' rel="noopener noreferrer">Terms & Conditions</Link></li>
                                        <li><Link to="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/refund" target='_blank' rel="noopener noreferrer">Cancellation & Refund Policy</Link></li>
                                        <li><Link to="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/privacy" target='_blank' rel="noopener noreferrer">Privacy Policy</Link></li>
                                        <li><Link to="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/shipping" target='_blank' rel="noopener noreferrer">Shipping & Delivery Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-contact-container col-xl-8 col-lg-8 col-sm-8">
                            <div className="contact-bg02">
                                <div className="footer-form-title section-title center-align mb-20 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                    <h2>
                                        Get In Touch
                                    </h2>
                                </div>                               
                                <form className="contact-form mt-30" onSubmit={formSubmitHandler}>
                                    <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-field p-relative c-name mb-10">                                    
                                            <input type="text" id="firstn" name="firstn" value={formData.firstn} onChange={handleInput} placeholder="Name" required />
                                        </div>                               
                                    </div>

                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-subject mb-10">                                   
                                            <input type="text" id="email" name="email" value={formData.email} onChange={handleInput} placeholder="Email" required />
                                        </div>
                                    </div>		
                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-subject mb-10">                                   
                                            <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleInput} placeholder="Phone No." required />
                                        </div>
                                    </div>	
                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-subject mb-10">                                   
                                            <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleInput} placeholder="Subject" required/>
                                        </div>
                                    </div>	
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-message mb-10">                                  
                                            <textarea name="message" id="message" value={formData.message} onChange={handleInput} cols="30" rows="10" placeholder="Write comments" required></textarea>
                                        </div>
                                        <div className="slider-btn">                                          
                                            <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s"><span>Submit Now</span></button>				
                                        </div>                             
                                    </div>
                                    </div>
                                </form> 

                                <div className="contact-submit-msg-container footer-form-title section-title center-align mt-20 text-center" data-animation="fadeInDown" style={{ display: isMsgVisible ? 'block' : 'none' }} data-delay=".4s">
                                    <h2>
                                        Submitted Successfully
                                    </h2>
                                </div>                           
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">                         
                           Copyright &copy; NinYanWays {(new Date().getFullYear())} . All rights reserved.         
                        </div>
                        <div className="col-lg-6 col-md-6 text-right text-xl-right">                       
                           <div className="footer-social">                                    
                                <a href="https://facebook.com/ninyanways" target='_blank' rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://twitter.com/NinWays?s=09" target='_blank' rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/ninyanways" target='_blank' rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                <a href="https://www.airbnb.co.in/users/show/404182738" target='_blank' rel="noopener noreferrer"><i className="fab fa-airbnb"></i></a>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default NewFooter
