import React from 'react';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import Slider from "react-slick"
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';

import bgVideo from '../../assets/video/bg_landing.mp4'
import Blog from "../../assets/img/bg/an-img-06.png"
import Blog1 from "../../assets/img/blog/property-card-1.jpeg"
import Blog2 from "../../assets/img/blog/property-card-5.jpeg"
import Blog3 from "../../assets/img/blog/property-card-6.jpg"
import Blog4 from "../../assets/img/blog/property-card-7.jpg"

import BGImg from "../../assets/img/bg/location-bg-1.jpg";
import './location.css'


export default function Location(){

    const activities = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        autoplaySpeed:5000,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    return(
    <>
        <main>
 
        <section className="breadcrumb-area d-flex align-items-center" style={{ backgroundImage : `url(${BGImg})`}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>Location</h2>    
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Location</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        <section id="services" className="main-content-section services-area pt-120 pb-90">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">    
                        <div className="section-title center-align text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                            <motion.h5 initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}> A haven of outstanding style and service surrounded by scenic nature</motion.h5>
                            <motion.h2 className='mb-10'  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}> Experience the Quintessence of Bliss </motion.h2 >
                                    
                            <motion.p initial={{opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} style={{textAlign: 'center'}}> Situated in the gorgeous town of Old Manali, NinYanWays is just 10 minutes from Mall Road and about 100 metres from the popular Club House. Your other favourite hangouts like Dylans, Lazy Dog and Drifters are also in the vicinity. </motion.p>

                            <motion.p style={{textAlign: 'center'}} initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}> You can explore the rich cultures of the Himalayas with mindful walks in the surrounding villages, observing the lifestyle and traditions of each hamlet, or head out for fun jaunts to nearby hot springs, waterfalls and ancient temples. Meanwhile, if you’re looking for an adrenaline rush, then you can schedule adventurous activities such as paragliding, hiking, and river-rafting, or ride in a cable car, helicopter, and/or hot air balloon. </motion.p>   
                                        
                        </div>
                    </div>
                </div>
            </div>
        </section>

 {/* section 2 header & paragraph */}

        <section id="act-sec" className="location-actv-sec main-content-section team-area fix p-relative pt-120 pb-100" style={{background: "#F7F5F1"}}>    
                <div className="container">  
                    <div className="row align-items-center"> 
                        <div className="col-lg-12">
                            <motion.div className="section-title center-align text-center" initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>

                                <h2 className='mb-10'> Nearby Activities</h2 >
                                        
                                <p > Despite the beautiful scenery and relaxing atmosphere, the outdoor activities available in Manali are a key lure for visitors. Manali is indeed Himachal's adventure capital, the #1 place to visit if you want to trek snowy peaks and verdant valleys, connect with your ancient roots, and soak up the exhilaration of new experiences.</p>
                    
                            </motion.div>
                        </div>
                    </div>

                    {
                        isTablet === true ? 
                            (
                                
                                <Slider className="slider-active" {...activities}>
                                    <div className="row act-grp pt-30">               
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rafting.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>River Rafting</h4>
                                                        <p>Fight the Gushing Waters</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/paragliding.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Paragliding</h4>
                                                        <p>Bird’s Eye View</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/skiing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Skiing</h4>
                                                        <p>Hit the Slopes</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/hiking.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Hiking</h4>
                                                        <p>Stroll through Nature</p>
                                                    </div>
                                                </div>
                                        </div> 

                                    </div>
                                    <div className="row act-grp pt-30">  
        
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/mountain_biking.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Mountain Biking</h4>
                                                        <p>Embrace the Adrenaline Rush</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/mountaineering.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Mountaineering</h4>
                                                        <p>Go Beyond Your Comfort</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/zorbing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Zorbing</h4>
                                                        <p>See the World Upside Down</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/jeep_safari.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Jeep Safari</h4>
                                                        <p>Travel with a Local</p>
                                                    </div>
                                                </div>
                                        </div>                    
                                    </div>
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/snow_boarding.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Snowboarding</h4>
                                                        <p>Curve It Up</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/river_crossing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>River Crossing</h4>
                                                        <p>Challenge Your Fears</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/anling.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Angling</h4>
                                                        <p>Catch Your Seafood</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/snow_scooter.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Snow Scooter</h4>
                                                        <p>Skid through the Snow</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/quad_biking.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Quad Biking </h4>
                                                        <p>Cross the Varied Terrains</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/camping.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Camping</h4>
                                                        <p>Witness Thousand Stars after Sun Sets</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/trekking.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Trekking</h4>
                                                        <p>Conquer Those Mountains</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/ziplining.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Ziplining</h4>
                                                        <p>Swish through the Jungles</p>
                                                    </div>
                                                </div>
                                        </div>  
                                    </div>
        
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rock_climbing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Rock Climbing</h4>
                                                        <p>Challenge Your Physical Strength</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/cable_car.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Cable Car Ropeway</h4>
                                                        <p>Witness the Miniature Town Below</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rappelling.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Rappelling</h4>
                                                        <p>Climb Down Cliffs</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container col-md-6">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/bungee.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Bungee Jumping</h4>
                                                        <p> Leap into the Arms of Nature</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
                                </Slider>
                        
                            )
                        :

                        isMobile === true ? 
                            (
                                <Slider className="slider-active" {...activities}>
                                    <div className="row act-grp pt-30">               
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rafting.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>River Rafting</h4>
                                                        <p>Fight the Gushing Waters</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/paragliding.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Paragliding</h4>
                                                        <p>Bird’s Eye View</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/skiing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Skiing</h4>
                                                        <p>Hit the Slopes</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/hiking.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Hiking</h4>
                                                        <p>Stroll through Nature</p>
                                                    </div>
                                                </div>
                                        </div>  
                                    </div>
        
                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/mountain_biking.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Mountain Biking</h4>
                                                        <p>Embrace the Adrenaline Rush</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/mountaineering.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Mountaineering</h4>
                                                        <p>Go Beyond Your Comfort</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
        
                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/zorbing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Zorbing</h4>
                                                        <p>See the World Upside Down</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/jeep_safari.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Jeep Safari</h4>
                                                        <p>Travel with a Local</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/snow_boarding.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Snowboarding</h4>
                                                        <p>Curve It Up</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/river_crossing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>River Crossing</h4>
                                                        <p>Challenge Your Fears</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
        
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/anling.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Angling</h4>
                                                        <p>Catch Your Seafood</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/snow_scooter.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Snow Scooter</h4>
                                                        <p>Skid through the Snow</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
        
                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/quad_biking.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Quad Biking </h4>
                                                        <p>Cross the Varied Terrains</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/camping.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Camping</h4>
                                                        <p>Witness Thousand Stars after Sun Sets</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/trekking.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Trekking</h4>
                                                        <p>Conquer Those Mountains</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/ziplining.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Ziplining</h4>
                                                        <p>Swish through the Jungles</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
        
                                    <div className="row act-grp pt-30">
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rock_climbing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Rock Climbing</h4>
                                                        <p>Challenge Your Physical Strength</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/cable_car.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Cable Car Ropeway</h4>
                                                        <p>Witness the Miniature Town Below</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
        
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rappelling.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Rappelling</h4>
                                                        <p>Climb Down Cliffs</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/bungee.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Bungee Jumping</h4>
                                                        <p> Leap into the Arms of Nature</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
                                </Slider>
                            )
                        :
                            (
                                <Slider className="slider-active" {...activities}>
                                    <div className="row act-grp pt-30">               
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rafting.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>River Rafting</h4>
                                                        <p>Fight the Gushing Waters</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/paragliding.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Paragliding</h4>
                                                        <p>Bird’s Eye View</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/skiing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Skiing</h4>
                                                        <p>Hit the Slopes</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/hiking.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Hiking</h4>
                                                        <p>Stroll through Nature</p>
                                                    </div>
                                                </div>
                                        </div>   
        
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/mountain_biking.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Mountain Biking</h4>
                                                        <p>Embrace the Adrenaline Rush</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/mountaineering.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Mountaineering</h4>
                                                        <p>Go Beyond Your Comfort</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/zorbing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Zorbing</h4>
                                                        <p>See the World Upside Down</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/jeep_safari.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Jeep Safari</h4>
                                                        <p>Travel with a Local</p>
                                                    </div>
                                                </div>
                                        </div>                    
                                    </div>
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/snow_boarding.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Snowboarding</h4>
                                                        <p>Curve It Up</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/river_crossing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>River Crossing</h4>
                                                        <p>Challenge Your Fears</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/anling.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Angling</h4>
                                                        <p>Catch Your Seafood</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/snow_scooter.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Snow Scooter</h4>
                                                        <p>Skid through the Snow</p>
                                                    </div>
                                                </div>
                                        </div> 
        
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/quad_biking.jpg" alt="img" />
                                                        </div>                                   
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Quad Biking </h4>
                                                        <p>Cross the Varied Terrains</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/camping.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Camping</h4>
                                                        <p>Witness Thousand Stars after Sun Sets</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/trekking.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Trekking</h4>
                                                        <p>Conquer Those Mountains</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/ziplining.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Ziplining</h4>
                                                        <p>Swish through the Jungles</p>
                                                    </div>
                                                </div>
                                        </div>  
                                    </div>
        
                                    <div className="row act-grp pt-30"> 
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rock_climbing.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Rock Climbing</h4>
                                                        <p>Challenge Your Physical Strength</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/cable_car.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Cable Car Ropeway</h4>
                                                        <p>Witness the Miniature Town Below</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/rappelling.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Rappelling</h4>
                                                        <p>Climb Down Cliffs</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-xl-3 act-grp-container">
                                                <div className="single-team single-team-activities-section mb-25" >
                                                    <div className="team-thumb">
                                                        <div className="brd">
                                                            <img src="../../assets/img/activities/bungee.jpg" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="team-info activities-card-info">
                                                        <h4>Bungee Jumping</h4>
                                                        <p> Leap into the Arms of Nature</p>
                                                    </div>
                                                </div>
                                        </div> 
                                    </div>
                                </Slider>
                            )
                    }

                    <div className="row justify-content-center">
                        <div className="section-title center-align text-center" style={{width:"300px"}}>
                            <Link to="/season" className="top-btn p-2 " style={{fontSize:"20px"}}>Activities By Season</Link>
                        </div>
                    </div>
                </div>
        </section>


        <section id="visit-sec" className="location-visit-sec main-content-section inner-blog pt-115 pb-110">
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <motion.h2 initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} >Places to Visit</motion.h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/hidimba_devi.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Hidimba Devi Temple</h2>
                                    <p>An ancient cave dedicated to Hidimbi Devi, wife of Bhima, a figure in the Indian epic Mahābhārata, the temple is surrounded by a cedar forest called Dhungiri Van Vihar at the foot of the Himālayas. The sanctuary is built over a huge rock jutting out of the ground which was worshipped as an image of the deity. The structure was built in 1553 by Maharaja Bahadur Singh.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/manu_temple.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Manu Temple</h2>
                                    <p>An ancient temple found at a distance of 3 km from the main market of Manali, which is dedicated to Manu, the Great Indian sage.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/vashisht_temple.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Vashisht Temple</h2>
                                    <p>Vashisht is a village located 3 km from Manali across the Beas River. This village is famous for its sulphurous hot springs and is sanctified by three main temples dedicated to Lord Vashisht, Lord Shiva and Lord Rama, built alongside the springs. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/mall_road.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Mall Road</h2>
                                    <p>The main street in Manali, where automobiles are not allowed to disturb pedestrians. The market has several showrooms, department stores, shops, restaurants and cafes. A Himachal emporium that offers handicrafts of Himachal Pradesh - like locally designed woollen products, branded clothes, pottery items, wooden souvenirs, and jewellery - is one of the main attractions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/rohtang_pass.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Rohtang Pass</h2>
                                    <p>A high mountain pass with an elevation of 3,980 m on the eastern end of the Pir Panjal Range of the Himalayas, around 51 km from Manali. It connects the Kullu Valley with the Lahaul and Spiti Valleys of Himachal Pradesh.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/bhrigu_lake.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Bhrigu Lake</h2>
                                    <p>At an elevation of around 4,300 metres in Kullu district, Himachal Pradesh, Bhrigut is located to the east of Rohtang Pass and is around 6 kilometres from Gulaba Village.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/solang_valley.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Solang Valley </h2>
                                    <p>14 kilometres to the northwest of the main town of Manali on the way to Rohtang, Solang Valley, is one of the most popular tourist destinations in Himachal Pradesh. It’s a favourite for adventure enthusiasts, who can partake in many activities from parachuting to paragliding, from horse riding to driving mini-jeeps available to tourists of all ages.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/hempta_pass.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Hampta Pass</h2>
                                    <p>Hamta Pass is a corridor in the Himalayas, between Chandra Valley in Lahaul and Kullu Valley of Himachal Pradesh. The pass is named after Hamta Village, below Sethan Village.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/chandratal_lake.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Chandratal Lake</h2>
                                    <p>Chandra Tal is a lake in the Lahaul part of the Lahul and Spiti district of Himachal Pradesh. Perched at a staggering altitude of 4,200 metres, this destination is slowly becoming one of the most attractive tourist stops. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/jogini_falls.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Jogni Waterfall</h2>
                                    <p>While it can be seen from Old Manali, you can view the famous fall up close by way of a trek from the town of Vashisht. It’s a gentle walk through the forest with vistas overlooking Rohtang and River Beas to your left.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 mt-10 mb-10">
                            <div className="places-single-card bsingle__post">
                                <div className="places-single-card-img col-lg-4 bsingle__post-thumb">
                                    <img src="../../assets/img/visit/bijli_mahadev.jpg" alt=""/>
                                </div>
                                <div className="places-single-card-content col-lg-8 bsingle__content">
                                    <h2>Bijli Mahadev</h2>
                                    <p> Bijli Mahadev is one of the most sacred temples in Himachel. It’s located at an altitude of about 2,460 m in Kullu Valley and is one of the oldest ancient temples in India and is dedicated to Lord Shiva.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

 {/* Place to visit  */}

 {/* card and Photo */}
        <section id="property-card" style={{background: "#F7F5F1"}} className="main-content-section blog-area p-relative fix pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-12">
                            <motion.div className="section-title center-align mb-50 text-center" initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>
                                <h5> 99 Ways to Bliss Out  </h5>
                                <h2>
                                   One Home Base 
                                </h2>
                                {/* <p>Proin consectetur non dolor vitae pulvinar. Pellentesque sollicitudin dolor eget neque viverra, sed interdum metus interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper dolor iaculis vel</p> */}
                            </motion.div>                           
                        </div>
                    </div>
                    <div className="row property-grp-cards">
                       <div className="property-card">
                            <div className="single-post2 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog1} alt="img"/>
                                </div>                    
                                <div className="blog-content2">    
                                    <h4>Conceptual Corridors</h4>  
                                    <p>From the Boho Bedroom to the Scandinavian Suite, each room was designed with a theme inspired by Himachal and the Himalayas.</p>
                                 <br></br>
                        
                                </div>
                            </div>
                        </div>
                         <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog2} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Smart Service</h4>  
                                    <p>Most suites have a smart TV and an intercom, but room service can also be ordered with the click of a button.
                                    <br></br>
                                    <br></br>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog3} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4 style={{marginBottom: '20px'}}>Renewable Responsibilities</h4>   
                                    <p>We practice ecological stewardship that includes food waste prevention, rubbish reduction, recycling and safe disposal.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog4} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Ethereal Elements</h4>   
                                    <p>Situated at the confluence of two rivers, and surrounded by apple trees, the property is also overlooked by multiple mountainpeaks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="section-title center-align text-center" style={{width:"300px"}}>
                            <Link to="/rooms" className="top-btn p-2 " style={{fontSize:"20px"}}> Tour Our Thematic Rooms</Link>
                        </div>
                    </div>
                </div>
        </section>

            {/* <section className="about-area about-p pt-120 pb-90 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="content-img col-lg-6 col-md-12 col-sm-12">
                            <div className="content-img-box s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src='../../assets/img/features/about_1.jpg' alt="img" />   
                            </div>
                        </div>
					    <div className="content-desc col-lg-6 col-md-12 col-sm-12" style={{background: "#F7F5F1"}}>
                            <div className="about-content s-about-content  wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title">  
                                    <motion.h2 style={{ paddingBottom: '10px'}}  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Ecotourism</motion.h2>                                   
                                
                                    <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>NinYanWays cares about the environment and promotes sustainable travel. We work hard to integrate nature-based practices, while ensuring locals are ecologically educated.</motion.p>

                                    <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>We promote and practise eco-friendly policies like food waste prevention, plastic reduction and safe disposal. We use water-efficient plumbing and at least 80% of our cuisine is sourced from the region, while 80% of lighting comes through energy-efficient LED bulbs.</motion.p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </main>
        
    </>
    )
}