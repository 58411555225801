import React , { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BrowserView, MobileView, TabletView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import { HashLink } from 'react-router-hash-link';
import ReactPlayer from 'react-player/lazy'
import {motion} from 'framer-motion';

import BGImg1 from "../../assets/img/slider/slider_bg_5.jpeg";
import BGImg2 from "../../assets/img/slider/slider_bg_6.jpeg";
import BGImg3 from "../../assets/img/slider/slider_bg_7.jpg";
import BGImg4 from "../../assets/img/slider/slider_bg_3.jpeg";

import policiesBg from "../../assets/img/bg/policy-abstract-bg.jpg";

import BGTest from "../../assets/img/bg/review-bg.jpg";
import BGVideo from "../../assets/img/bg/video-bg.png"
import Slider from "react-slick"
import FsLightbox from 'fslightbox-react';

import About from "../../assets/img/bg/an-img-02.png"
import About1 from "../../assets/img/features/about_1.jpg"
import About2 from "../../assets/img/features/about_2.jpg"
import About3 from "../../assets/img/features/signature.png"

import Hotel from "../../assets/img/bg/an-img-01.png"
import Hotel1 from "../../assets/img/icon/fe-icon01.png"
import Hotel2 from "../../assets/img/icon/fe-icon04.png"
import Hotel3 from "../../assets/img/icon/fe-icon05.png"
import Hotel4 from '../../assets/img/icon/hiking.png'

import service1 from "../../assets/img/gallery/gallary_img_1.jpg"
import service2 from "../../assets/img/gallery/gallary_img_2.jpg"
import service3 from "../../assets/img/gallery/gallary_img_3.jpg"
import service4 from "../../assets/img/gallery/gallary_img_4.jpg"

import plant_room1 from "../../assets/img/gallery/plant-room.jpg"
import boho_room2 from "../../assets/img/gallery/boho-room.jpg"
import stone_room3 from "../../assets/img/gallery/stone-room.jpg"
import artdeco_room4 from "../../assets/img/gallery/artdeco-room.jpg"

import icon1 from "../../assets/img/icon/sve-icon1.png"
import icon2 from "../../assets/img/icon/sve-icon2.png"
import icon3 from "../../assets/img/icon/sve-icon3.png"
import icon4 from "../../assets/img/icon/sve-icon4.png"
import icon5 from "../../assets/img/icon/sve-icon5.png"
import icon6 from "../../assets/img/icon/sve-icon6.png"
import Resort from "../../assets/img/bg/an-img-02.png"
import Resort1 from "../../assets/img/features/feature.png"
import pricing1 from "../../assets/img/bg/an-img-02.png"
import avatar1 from "../../assets/img/testimonial/testi_avatar.png"
import avatar2 from "../../assets/img/testimonial/testi_avatar_02.png" 
import avatar3 from "../../assets/img/testimonial/testi_avatar_03.png"
import Aicon from "../../assets/img/testimonial/qt-icon.png"
import Aicon1 from "../../assets/img/testimonial/review-icon.png"
import Booking from "../../assets/img/bg/booking-img.png"
import Play from "../../assets/img/bg/play-button.png"

import Blog from "../../assets/img/bg/an-img-06.png"
import Blog1 from "../../assets/img/blog/property-card-1.jpeg"
import Blog2 from "../../assets/img/blog/property-card-5.jpeg"
import Blog3 from "../../assets/img/blog/property-card-6.jpg"
import Blog4 from "../../assets/img/blog/property-card-7.jpg"

import flipbox_1 from "../../assets/img/blog/flipbox-1.jpeg"
import flipbox_2 from "../../assets/img/blog/flipbox-2.jpg"

import Brand1 from "../../assets/img/brand/b-logo1.png"
import Brand2 from "../../assets/img/brand/b-logo2.png"
import Brand3 from "../../assets/img/brand/b-logo3.png"
import Brand4 from "../../assets/img/brand/b-logo4.png"
import Brand5 from "../../assets/img/brand/b-logo5.png"

import boho_room from '../../assets/img/room-img/boho/boho_3.jpg'
import himachali_room from '../../assets/img/room-img/himachali/himachali_4.jpg'
import artDeco_room from '../../assets/img/room-img/art-deco/art_deco_1.jpeg'
import plant_room from '../../assets/img/room-img/plant/plant_3.jpg'
import scandinavian_room from '../../assets/img/room-img/scandinavian/scandinavian_5.jpg'
import vintage_river_room from '../../assets/img/room-img/vintage-river/vintage_river_1.jpg'
import mud_room from '../../assets/img/room-img/mud/mud_1.jpg'
import rustic_room from '../../assets/img/room-img/rustic/rustic_1.jpg'
import stone_room from '../../assets/img/room-img/stone/stone_1.jpg'
import vintage_mountain_room from '../../assets/img/room-img/vintage-mountain/vintage_mountaine_1.jpg'
import scandanavian_triple_room from '../../assets/img/room-img/scandinavian/scandinavian_2.jpg'
import vintage_triple_room from '../../assets/img/room-img/vintage-mountain/vintage_mountaine_2.jpg'

import "./Main.css";
import bgVideoDesk from '../../assets/video/ninyanways_landing_desktop.mp4'
import bgVideoMob from '../../assets/video/ninwanways_landing_mob.mp4'
import videoLeft from '../../assets/video/videoLeft_snowfall.mp4'



const Main = () => {
    const [yShow, setyShow] = useState()
    const [hShow, sethShow] = useState()
    const [animation, setanimation] = useState()
    const [mobile, setmobile] = useState(false)
    const [tabMenu, tabActive] = useState({all : true})

    const [toggler, setToggler] = useState(false);
    const [activeImage , setActiveImage] = useState(1)
    const [images , setImages] = useState([
        'assets/img/gallery/gallary_img_1.jpg',
        'assets/img/gallery/gallary_img_2.jpg',
        'assets/img/gallery/gallary_img_3.jpg',
        'assets/img/gallery/gallary_img_4.jpg',
        // 'assets/img/gallery/room-img05.png',
        // 'assets/img/gallery/room-img06.png'
	])


    const home = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        autoplaySpeed:2000,
        centerMode: true,
        centerPadding: "200px",
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "200px",
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const service = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const carousal = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 700,
        autoplay: true,
        autoplaySpeed:2000,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const testimonial = {
        initialIndex: 2,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const brand = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const rooms = {
        dots: false,
        // dotsClass: 'room-dot',
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const review = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    const headerAnimation ={
        x: 100, // move element 100 pixels to the right
        y: -50, // move element 50 pixels up
        opacity: 0.5, // reduce element's opacity to 50%
        rotate: 90, // rotate element 90 degrees clockwise
        scale: 1.2, // increase element's size by 20%
        borderRadius: "50%", // round element's corners
        transition: {
          duration: 0.5, // animation duration in seconds
          ease: "easeInOut", // easing function
          delay: 0.2, // animation delay in seconds
          repeat: Infinity, // number of times to repeat animation
          repeatType: "mirror", // type of repeat animation
        },
    }

    let screenWidth = window.innerWidth;

    // console.log('tab = ',isTablet)
    // console.log('screen = ', screenWidth)

  return (
    <>
        <main>


         {/* addd video sectrion here
             step-1 add the video template from chat gpt
             step-2 add the required text upon it 
             step-3 
         */}

            <section id="home" className="slider-area fix p-relative">


                {
                    isTablet === true ?
                        (
                            
                            <div className="video-container">
                                {/* <video autoPlay muted loop id="video-bg">
                                    <source src={bgVideoDesk} type="video/mp4" />
            
                                    <h1> Video container</h1>
                                </video> */}
                                <ReactPlayer url={bgVideoDesk} loop={true} muted={true} playing={true} id='video-bg' width='100%' height='100%' />
                                <div className="text-container">
                                    {/* <h2>NinYanWays</h2> */}
                                    <h2 >...To Bliss Out!</h2>
                                </div>
                            </div>
                            
                        )
                    :

                    isMobile === true ? 
                        (
                            
                            <div className="video-container">
                            <ReactPlayer url={bgVideoMob} loop={true} muted={true} playing={true} id='video-bg' width='100%' height='100%' />
                                {/* <video autoPlay muted loop id="video-bg">
                                    <source src={bgVideoMob} type="video/mp4" />
            
                                    <h1> Video container</h1>
                                </video> */}
                                <div className="text-container">
                                    {/* <h2>NinYanWays</h2> */}
                                    <h2>...to bliss out!</h2>
                                </div>
                            </div>    
                            
                        )     
                    :
                        (
                            
                            <div className="video-container">
                                {/* <video autoPlay muted loop id="video-bg">
                                    <source src={bgVideoDesk} type="video/mp4" />
            
                                    <h1> Video container</h1>
                                </video> */}
                                <ReactPlayer url={bgVideoDesk} loop={true} muted={true} playing={true} id='video-bg' width='100%' height='100%' />
                                <div className="text-container">
                                    {/* <h2>NinYanWays</h2> */}
                                    <h2 >...To Bliss Out!</h2>
                                </div>
                            </div>
                        
                        )
                }                
            </section>

 
            <BrowserView>
                <section id="home-carousal-d" className="main-content-section slider-area fix p-relative pt-120 pb-120" style={{background: "#f7f5f1"}}>
                <div className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align mb-40 text-center">
                                    <motion.h2 className='mb-10'  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} >A Boutique Stay in the Himalayas</motion.h2>

                                    <motion.p initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} style={{ textAlign: 'center'}}>NinYanWays offers you a blend of holistic living with luxurious comfort. You’ll find it’s the perfect place to reconnect with yourself and to explore cultures of loving-kindness while indulging in natural beauty and peaceful harmony, inundated by tranquility all around.</motion.p>
                                </div>
                            </div>
                        </div>  
                    <Slider className="slider-active" {...home}>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg1})`, backgroundSize: 'cover', backgroundPosition: 'center top'}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg2})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg3})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg4})`, background_size: "cover"}}>
                            </div>
                        </div>
                    </Slider>
                </section>

            </BrowserView>

            <MobileView>
                <section id="home-carousal-m" className="main-content-section slider-area fix p-relative pt-90 pb-90" style={{background: "#f7f5f1"}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align mb-50 text-center">
                                    <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }} >A Boutique Stay in the Himalayas</motion.h2>

                                    <motion.p initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>NinYanWays offers you a blend of holistic living with luxurious comfort. You’ll find it’s the perfect place to reconnect with yourself and to explore cultures of loving-kindness while indulging in natural beauty and peaceful harmony, inundated by tranquility all around.</motion.p>
                                </div>
                            </div>
                        </div>  
                        <Slider className="row slider-active restaurant-sec3-slider" {...carousal}>
                            <div className="col-xl-2">
                                <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg1})`}}>
                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg2})`, background_size: "cover"}}>
                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg3})`, background_size: "cover"}}>
                                </div>
                            </div>
                            <div className="col-xl-2">
                                <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg4})`, background_size: "cover"}}>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </section>
            </MobileView>

            <section id="services" style={{ overflowX: 'hidden'}} className="main-content-section services-area pt-120 pb-90">
                <div className="container">

                    
                    <div className="row justify-content-center mb-20">
                        <div className="col-xl-12">    
                            <div className="section-title center-align mb-30 text-center">
                                <motion.h5   initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Idealism-Elevated Materialism in Rustic-but-Modern Manali</motion.h5>
                                <motion.h2  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>
                                    Selecting A Suite That Speaks to Your Spirit
                                </motion.h2>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="my-masonry text-center mb-10">
                                <div className="button-group filter-button-group">
                                    <button className={`${tabMenu.all && "active"}`} onClick={()=>tabActive({all:true})} data-filter="*">All</button>
                                    <button className={`${tabMenu.river && "active"}`} onClick={()=>tabActive({river:true})} data-filter=".river">River Facing Balcony</button>
                                    <button className={`${tabMenu.mountain && "active"}`} onClick={()=>tabActive({mountain:true})} data-filter=".mountain">Mountain Facing</button>	
                                    <button className={`${tabMenu.double && "active"}`} onClick={()=>tabActive({double:true})} data-filter=".double">Double Rooms with Two Balconies</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        isTablet === true ? 
                            (
                             <>
                                {tabMenu.all && 
                                    <Slider className="rooms-browser slider-active" {...rooms}>
                                    
                                    <div className="row home-rooms-section services-active">  
                                    
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={plant_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Plant</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer">Plant Room</a>
                                                        </h4>     
                                                        <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={boho_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Boho</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">Boho Room</a>
                                                        </h4>     
                                                        <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={himachali_room} alt="img" />
                
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Himachali</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">Himachali Room</a>
                                                        </h4>     
                                                        <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={artDeco_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Art Deco</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">Art Deco Room</a>
                                                        </h4>     
                                                        <p>Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandinavian_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Room</a>
                                                        </h4>     
                                                        <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_river_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="row home-rooms-section services-active"> 
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={stone_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Stone</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">Stone Room</a>
                                                        </h4>     
                                                        <p>The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_mountain_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
        
                                                    <img src={mud_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Mud</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">Mud Room</a>
                                                        </h4>     
                                                        <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={rustic_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Rustic</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">Rustic Room</a>
                                                        </h4>     
                                                        <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                    </div>
                                    
                                    {/* <div className="row home-rooms-section services-active">  
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_triple_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">Vintage Triple Room</a>
                                                        </h4>     
                                                        <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandanavian_triple_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Triple Room</a>
                                                        </h4>     
                                                        <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_triple_room} alt="img" />
            
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                            </ul>
                                                        </div>    
            
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandanavian_triple_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                            </ul>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>
        
                                    </div> */}
                                    
                                    </Slider>
                                }
        
                                <>
                                    {tabMenu.river &&
                                    <div className="row home-rooms-section services-active river">  
                                    
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={plant_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Plant</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer">Plant Room</a>
                                                        </h4>     
                                                        <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={boho_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Boho</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">Boho Room</a>
                                                        </h4>     
                                                        <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={himachali_room} alt="img" />
                
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Himachali</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">Himachali Room</a>
                                                        </h4>     
                                                        <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={artDeco_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Art Deco</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">Art Deco Room</a>
                                                        </h4>     
                                                        <p>Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandinavian_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Room</a>
                                                        </h4>     
                                                        <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_river_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
        
                                    {tabMenu.mountain &&
                                    <div className="row home-rooms-section services-active mountain"> 
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={stone_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Stone</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">Stone Room</a>
                                                        </h4>     
                                                        <p>The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_mountain_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
        
                                                    <img src={mud_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Mud</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">Mud Room</a>
                                                        </h4>     
                                                        <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={rustic_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Rustic</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">Rustic Room</a>
                                                        </h4>     
                                                        <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                    </div>}
        
                                    {tabMenu.double &&
                                    <div className="row home-rooms-section services-active double">  
        
                                        <div style={{display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_triple_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">Vintage Triple Room</a>
                                                        </h4>     
                                                        <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandanavian_triple_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Triple Room</a>
                                                        </h4>     
                                                        <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_triple_room} alt="img" />
            
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                            </ul>
                                                        </div>    
            
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandanavian_triple_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                            </ul>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>
        
                                    </div>}
                                </>
                                </>
        
                        
                            )
                        :

                        isMobile === true ? 
                            (
                                <>
                                {tabMenu.all &&
                                    <Slider className="slider-active" {...rooms}>
                                    
                                        {/* Category -1 (1st half) */}
                                        <div className="row home-rooms-section services-active">  
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={plant_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Plant</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer">Plant Room</a>
                                                            </h4>     
                                                            <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={boho_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Boho</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">Boho Room</a>
                                                            </h4>     
                                                            <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row home-rooms-section services-active">
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={himachali_room} alt="img" />
                    
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Himachali</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">Himachali Room</a>
                                                            </h4>     
                                                            <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={artDeco_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Art Deco</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">Art Deco Room</a>
                                                            </h4>     
                                                            <p>Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
        
                                        {/* Category -1 (2nd half) */}
                                        <div className="row home-rooms-section services-active">     
    
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandinavian_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Room</a>
                                                            </h4>     
                                                            <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_river_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                            </h4>     
                                                            <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        {/* Category -2 (1st half) */}
                                        <div className="row home-rooms-section services-active">  
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={stone_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Stone</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">Stone Room</a>
                                                            </h4>     
                                                            <p>The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_mountain_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                            </h4>     
                                                            <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        {/* Category -2 (2nd half) */}
                                        <div className="row home-rooms-section services-active">  
                                        <   div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
        
                                                        <img src={mud_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Mud</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">Mud Room</a>
                                                            </h4>     
                                                            <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={rustic_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Rustic</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">Rustic Room</a>
                                                            </h4>     
                                                            <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        {/* Category -3  */}
                                        {/* <div className="row home-rooms-section services-active">  

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_triple_room} alt="img" />
            
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                               
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                            </ul>
                                                        </div>    
            
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandanavian_triple_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                            </ul>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_triple_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                               
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">Vintage Triple Room</a>
                                                            </h4>     
                                                            <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandanavian_triple_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                            
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Triple Room</a>
                                                            </h4>     
                                                            <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                        </div> */}
                            
                                    </Slider>
                                } 
        
                                {tabMenu.river &&
                                    <Slider className="slider-active river" {...rooms}>
                                        {/* Category -1 (1st half) */}
                                        <div className="row home-rooms-section services-active">  
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={plant_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Plant</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer">Plant Room</a>
                                                            </h4>     
                                                            <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={boho_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Boho</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">Boho Room</a>
                                                            </h4>     
                                                            <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Category -1 (2nd half) */}
                                        <div className="row home-rooms-section services-active">
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={himachali_room} alt="img" />
                    
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Himachali</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">Himachali Room</a>
                                                            </h4>     
                                                            <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={artDeco_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Art Deco</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">Art Deco Room</a>
                                                            </h4>     
                                                            <p>Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        {/* Category -1 (3rd half) */}
                                        <div className="row home-rooms-section services-active">     
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandinavian_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Room</a>
                                                            </h4>     
                                                            <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_river_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                            </h4>     
                                                            <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                }
        
                                {tabMenu.mountain &&
                                    <Slider className="slider-active river" {...rooms}>
                                        {/* Category -2 (1st half) */}
                                        <div className="row home-rooms-section services-active">  
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={stone_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Stone</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">Stone Room</a>
                                                            </h4>     
                                                            <p>The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_mountain_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                            </h4>     
                                                            <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        {/* Category -2 (2nd half) */}
                                        <div className="row home-rooms-section services-active">  
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
        
                                                        <img src={mud_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Mud</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">Mud Room</a>
                                                            </h4>     
                                                            <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={rustic_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Rustic</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">Rustic Room</a>
                                                            </h4>     
                                                            <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                }
        
                                {tabMenu.double &&
                                    <Slider className="slider-active river" {...rooms}>
                                        {/* Category -3  */}
                                        <div className="row home-rooms-section services-active">  
                                            <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_triple_room} alt="img" />
        
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">Vintage Triple Room</a>
                                                            </h4>     
                                                            <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                                <div className="single-services mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandanavian_triple_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                            </ul>
                                                        </div>    
        
                                                        <div className="room-details">
                                                            <h4>
                                                                <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Triple Room</a>
                                                            </h4>     
                                                            <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                                            <div className="col-md-6 room-details-btn">
                                                                <div className="slider-btn">                                          
                                                                    <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={vintage_triple_room} alt="img" />
            
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                {/* <li>$600/Night</li> */}
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                            </ul>
                                                        </div>    
            
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                                <div className='coming-soon-text'>
                                                    <h2>Coming Soon...</h2>
                                                </div>
                                                <div className="coming-soon-content single-services home-rooms-single-card mb-30">
                                                    <div className="services-thumb">
                                                        <img src={scandanavian_triple_room} alt="img" />
                                                        <div className="day-book day-book-room">
                                                            <ul>
                                                                <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                            </ul>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                }
                                </>
                            )
                        :
                            (
                                <>
                                {tabMenu.all &&
                                    <Slider className="rooms-browser slider-active" {...rooms}>
                                    
                                    <div className="row home-rooms-section services-active">  
                                    
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={plant_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Plant</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer">Plant Room</a>
                                                        </h4>     
                                                        <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={boho_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Boho</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">Boho Room</a>
                                                        </h4>     
                                                        <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={himachali_room} alt="img" />
                
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Himachali</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">Himachali Room</a>
                                                        </h4>     
                                                        <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={artDeco_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Art Deco</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">Art Deco Room</a>
                                                        </h4>     
                                                        <p>Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandinavian_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Room</a>
                                                        </h4>     
                                                        <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_river_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="row home-rooms-section services-active"> 
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={stone_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Stone</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">Stone Room</a>
                                                        </h4>     
                                                        <p>The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_mountain_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
        
                                                    <img src={mud_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Mud</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">Mud Room</a>
                                                        </h4>     
                                                        <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={rustic_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Rustic</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">Rustic Room</a>
                                                        </h4>     
                                                        <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                    </div>
                                    
                                    {/* <div className="row home-rooms-section services-active">  
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_triple_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">Vintage Triple Room</a>
                                                        </h4>     
                                                        <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandanavian_triple_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                          
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Triple Room</a>
                                                        </h4>     
                                                        <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                            <div className='coming-soon-text'>
                                                <h2>Coming Soon...</h2>
                                            </div>
                                            <div className="coming-soon-content single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_triple_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                           
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                            <div className='coming-soon-text'>
                                                <h2>Coming Soon...</h2>
                                            </div>
                                            <div className="coming-soon-content single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandanavian_triple_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                        </ul>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
        
                                    </div> */}
                                    
                                    </Slider>
                                }
        
                                <>
                                    {tabMenu.river &&
                                    <div className="row home-rooms-section services-active river">  
                                    
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={plant_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Plant</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE' target='_blank' rel="noopener noreferrer">Plant Room</a>
                                                        </h4>     
                                                        <p>The living curations featured in the Plant Room not only add to the aesthetic value but also have health benefits like air-purification.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/52633418?source_impression_id=p3_1682934661_zylwuCwpJKE0O3oE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={boho_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Boho</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI' target='_blank' rel="noopener noreferrer">Boho Room</a>
                                                        </h4>     
                                                        <p>The Boho room reflects a trendy lifestyle that revolves around nature, love, and creativity, which incorporates a palette of earthy colours.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283257?source_impression_id=p3_1682935234_yrPQG17m1DUbFFjI" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={himachali_room} alt="img" />
                
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Himachali</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL' target='_blank' rel="noopener noreferrer">Himachali Room</a>
                                                        </h4>     
                                                        <p>The Himachali room is a spacious river-facing abode, bathed in natural light, offering artifact features and age-old local design.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338836?source_impression_id=p3_1682934906_pvlpPRumcUOEmcEL" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={artDeco_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Art Deco</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO' target='_blank' rel="noopener noreferrer">Art Deco Room</a>
                                                        </h4>     
                                                        <p>Our Art Deco room boasts an inherent glamour and traditional luxury characterized by bold colour, metallic finishes, and visual drama.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53283155?source_impression_id=p3_1682935231_CpiUDkJX5fVtZAJO" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandinavian_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Room</a>
                                                        </h4>     
                                                        <p>The Scandinavian room’s design is marked by a focus on clean simple lines, minimalism, and functionality without sacrificing beauty.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll"  target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_river_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1688016848_XF5YIK4c8%2BeAS8xj" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
        
                                    {tabMenu.mountain &&
                                    <div className="row home-rooms-section services-active mountain"> 
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={stone_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Stone</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE' target='_blank' rel="noopener noreferrer">Stone Room</a>
                                                        </h4>     
                                                        <p>The Stone room (triple) has an aesthetic impact that matches the property’s natural elements, juxtaposed with certain metallic features.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339799?source_impression_id=p3_1682935097_XWSy%2FxxTN0waeV%2FE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_mountain_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3' target='_blank' rel="noopener noreferrer">Vintage Room</a>
                                                        </h4>     
                                                        <p>The Vintage room features retro brass lamps, a worn rug and old-fashioned design, like the antique-looking frames on the walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339923?source_impression_id=p3_1682935098_Gez1yjGknAfRnvx3" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
        
                                                    <img src={mud_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Mud</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc' target='_blank' rel="noopener noreferrer">Mud Room</a>
                                                        </h4>     
                                                        <p>The Mud room (triple) has organic highlights, au natural features that provide a down-to-earth feel despite modern conveniences.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339293?source_impression_id=p3_1682934953_LXvFZRxHRrfFiDIc" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={rustic_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Rustic</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7' target='_blank' rel="noopener noreferrer">Rustic Room</a>
                                                        </h4>     
                                                        <p>A Rustic room (triple) is available to those who wish to be inspired by understated elegance, from the worn rug to the the shabby-chic walls.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53339669?source_impression_id=p3_1682935046_AydMXG0Ig3EW6eq7" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                    </div>}
        
                                    {tabMenu.double &&
                                    <div className="row home-rooms-section services-active double">  
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_triple_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE' target='_blank' rel="noopener noreferrer">Vintage Triple Room</a>
                                                        </h4>     
                                                        <p>The Vintage triple room was designed on similar lines as our Vintage double room, with the addition of an extra bed and even more decorated space.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53338597?source_impression_id=p3_1682935604_1lbLuDDfgM%2BqblyE" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div style={{ display: 'none'}} className="col-xl-4 col-md-6 mb-40">
                                            <div className="single-services mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandanavian_triple_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                    <div className="room-details">
                                                        <h4>
                                                            <a href='https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip' target='_blank' rel="noopener noreferrer">Scandinavian Triple Room</a>
                                                        </h4>     
                                                        <p>The Scandanavian triple room was designed on similar lines as our Scandinavian double room. It is slightly bigger with a provision of additional bedding.</p>
                                                        <div className="col-md-6 room-details-btn">
                                                            <div className="slider-btn">                                          
                                                                <a href="https://www.airbnb.co.in/rooms/53337865?source_impression_id=p3_1682935615_Sc7wkTaSq6qCrgip" className="btn ss-btn smoth-scroll" target='_blank' rel="noopener noreferrer">Check Availability</a>				
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Coming soon room card */}
                                        <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                            <div className='coming-soon-text'>
                                                <h2>Coming Soon...</h2>
                                            </div>
                                            <div className="coming-soon-content single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={vintage_triple_room} alt="img" />
        
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            {/* <li>$600/Night</li> */}
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Vintage Triple</a></li>
                                                        </ul>
                                                    </div>    
        
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-6 mb-40 coming-soon-card">
                                            <div className='coming-soon-text'>
                                                <h2>Coming Soon...</h2>
                                            </div>
                                            <div className="coming-soon-content single-services home-rooms-single-card mb-30">
                                                <div className="services-thumb">
                                                    <img src={scandanavian_triple_room} alt="img" />
                                                    <div className="day-book day-book-room">
                                                        <ul>
                                                            <li><a href='#' target='_blank' rel="noopener noreferrer">Scandinavian Triple</a></li>
                                                        </ul>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
        
                                    </div>}
                                </>
                                </>
                            )
                    }


                </div>
            </section>


            <section className="main-content-section about-area about-p pt-120 pb-120 p-relative fix" style={{background: "#f7f5f1"}}>
                <div className="animations-02"><img src='../../assets/img/bg/mountains.png' style={{ opacity: '0.1'}} alt="contact-bg-an-02"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <motion.div className="s-about-img p-relative  wow fadeInLeft animated"   >
                    
                                <div className="left-video-block">
                                    
                                    <ReactPlayer url={videoLeft} loop={true} muted={true} playing={true} id='video-bg' width='100%' height='100%' />
                                 </div>
                            </motion.div>
                        </div>
					    <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title left-video-content">  
                                    <motion.h2 className='pb-05'  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Your Home Away From Home...</motion.h2>                                   
                                
                                   <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>We’ve all found ourselves feeling that we need a vacation from our vacation, which is why sightseeing ambitions should be balanced with our self-care agenda. So, while adventures are great, elevated accommodations are essential.</motion.p>
                                    <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>NinYanWays is a Hinglish way to say ninety-nine ways. “To bliss out” has been added as the tagline to complete the meaning, making it clear that our main aim is to bring everything you could ask for while you’re on a holiday - 99 activities included - even if your travel plans happen to be contained to the art of stoic leisure.</motion.p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 
            <section id="property-card" className="main-content-section blog-area p-relative fix pt-120 pb-90" >
                <div className="container">
                    <div className="property-grp-cards row">
                       <div className="property-card">
                            <div className="single-post2 hover-zoomin mb-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog1} alt="img"/>
                                </div>                    
                                <div className="blog-content2">    
                                    <h4>Conceptual Corridors</h4>  
                                    <p>From the Boho Bedroom to the Scandinavian Suite, each room was designed with a theme inspired by Himachal and the Himalayas.</p>
                                 <br></br>
                        
                                </div>
                            </div>
                        </div>
                         <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog2} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Smart Service</h4>  
                                    <p>Most suites have a smart TV and an intercom, but room service can also be ordered with the click of a button.
                                    <br></br>
                                    <br></br>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog3} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Renewable Responsibilities</h4>   
                                    <p>We practice ecological stewardship that includes food waste prevention, rubbish reduction, recycling and safe disposal.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="property-card">
                            <div className="single-post2 mb-30 hover-zoomin wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="blog-thumb2" style={{ display: 'block', position: 'relative', overflow: 'hidden'}}>
                                    <img src={Blog4} alt="img"/>
                                </div>
                                <div className="blog-content2">                                    
                                    <h4>Ethereal Elements</h4>   
                                    <p>Situated at the confluence of two rivers, and surrounded by apple trees, the property is also overlooked by multiple mountainpeaks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="service-details2" className="home-actv-card-sec main-content-section pt-120 pb-120 p-relative" style={{background: "#f7f5f1"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">

                                <motion.h5  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Attractions and Activities</motion.h5>
                                <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Making the Most of the Middle of Manali</motion.h2>

                                {/* <motion.h5 initial={{ opacity: 0 , x: -100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>What if the destination is the perfect point of departure?</motion.h5>
                                <motion.h2  initial={{ opacity: 0 , x: -100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>
                                    Compared to the journey, does the destination matter?
                                </motion.h2> */}
                                {/* <p>What if the destination is the perfect point of departure?</p> */}
                            </div>
                        </div>
                            <div className="col-lg-3 col-md-6 attraction-sec-container">
                               <div className="services-08-item attraction-sec-card mb-30">
                                     <div className="services-icon2">
                                       <img src={Hotel4} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel4} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Treks</Link></h3>
                                        <p>Explore <b>breathtaking treks</b> near Manali: Rohtang Pass, Bhrigu Lake, Hampta Pass, Beas Kund. Spectacular mountain views, alpine meadows, and adventure await!</p>
                                         {/* <Link to="/service-details">Read More <i className="fal fa-long-arrow-right"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 attraction-sec-container">
                                <div className="services-08-item attraction-sec-card mb-30">
                                    <div className="services-icon2">
                                       <img src={Hotel1} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel1} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Hot Spring</Link></h3>
                                        <p>Manali has a few <b>hot springs</b>- Vashisht, Kalath and Khir Ganga namely - known for their therapeutic properties and popular among tourists seeking ralaxation and rejuvenation.</p>
                                        {/* <Link to="/service-details">Read More <i className="fal fa-long-arrow-right"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 attraction-sec-container">                                  
                                <div className="services-08-item attraction-sec-card mb-30">
                                     <div className="services-icon2">
                                       <img src={Hotel2} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                   <img src={Hotel2} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Ancient Temples</Link></h3>
                                       <p>Manali has several <b>ancient temples</b> such as Hidimba Devi Temple, Manu Temple and Vashisht Temple - each with historical significance and spiritual importance.
                                       </p>
                                         {/* <Link to="/service-details">Read More <i className="fal fa-long-arrow-right"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 attraction-sec-container">
                               <div className="services-08-item attraction-sec-card mb-30">
                                     <div className="services-icon2">
                                       <img src={Hotel3} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel3} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3 style={{marginBottom: '10px'}}><Link to="/service-details">Waterfalls</Link></h3>
                                        <p className='mb-0'>We have several breathtaking <b>waterfalls</b> such as Jogini Falls, Rahala Falls and Jana Falls known for their picturesque beauty and for feeding the rivers that create and flow through all valleys.</p>
                                         {/* <Link to="/service-details">Read More <i className="fal fa-long-arrow-right"></i></Link> */}
                                    </div>
                                </div>
                            </div>

                        <div className="col-lg-6 attraction-sec-btn">
                                <div className="home-sec7-btn pricing-btn">
                                    <HashLink to="/location#act-sec" className="btn ss-btn">Nearby Activities</HashLink>
                                </div>
                        </div>   
                        <div className="col-lg-6 attraction-sec-btn">
                                <div className="home-sec7-btn pricing-btn">
                                    <HashLink to="/season" className="btn ss-btn" state={{season_name: 'spring'}}>Activities by Season</HashLink>
                                </div>
                        </div>   
                    </div>
                </div>
            </section>

            <div className="home-policies-section brand-area pt-60 pb-60"  style={{display: 'none',background :"#feeac0", backgroundImage: `url(${policiesBg})`}}>
                <div className="container">
                    <div className="row brand-active" >
                        <div className="col-xl-3">
                            <div className="single-brand">
                                {/* <img src={Brand1} alt="img"/> */}
                                <div className="home-policies-btn section-title center-align text-center">
                                    <a href="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/terms" target='_blank' rel="noopener noreferrer" className="btn ss-btn">TERMS & CONDITIONS</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="single-brand">
                                 {/* <img src={Brand2} alt="img"/> */}
                                 <div className="home-policies-btn section-title center-align text-center">
                                    <a href="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/refund" target='_blank' rel="noopener noreferrer" className="btn ss-btn">CANCELLATION & REFUND POLICY</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="single-brand">
                                 {/* <img src={Brand3} alt="img"/> */}
                                 <div className="home-policies-btn section-title center-align text-center">
                                    <a href="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/privacy" target='_blank' rel="noopener noreferrer" className="btn ss-btn">PRIVACY POLICY</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="single-brand">
                                  {/* <img src={Brand4} alt="img"/> */}
                                  <div className="home-policies-btn section-title center-align text-center">
                                    <a href="https://merchant.razorpay.com/policy/Mnn8RM5ng5a5hs/shipping" target='_blank' rel="noopener noreferrer" className="btn ss-btn">SHIPPING & DELIVERY POLICY</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <section className="main-content-section testimonial-area pt-120 pb-120 p-relative fix" >
                <div className="container">
                    <div className="row">
                         <div className="col-lg-12">
                            <motion.div className="section-title center-align mb-50 text-center" initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>
                                <h5 >The Future Reflects the Past</h5>
                                <h2 >Educated Expectations</h2>
                            </motion.div>
                        </div>
                    </div>


                    {

                       isTablet === true ? 

                                screenWidth > 992 && screenWidth < 1200 ? 
                                
                                <Slider className="slider-active" {...review}>
                                    <div className="review-slider row align-items-center">
                                            <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Adhideb</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Kalpana</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Chetan</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                            <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Ritesh</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Shushank</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                        Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                        Cannot wait to go back because this property deserves being visited more than once”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Purva</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                        
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                    </div>
                                </Slider>
                                
                                :
                            (
                                    <Slider className="slider-active" {...review}>
                                        <div className="review-slider row align-items-center">
                                                <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Adhideb</h3>
                                                                    </div>
            
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
            
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Kalpana</h3>
                                                                    </div>
            
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                            <br></br>
                                                            </p>
                                                            </div>
            
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="review-slider row align-items-center">
                                                <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Chetan</h3>
                                                                    </div>
            
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
            
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Ritesh</h3>
                                                                    </div>
            
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
            
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="review-slider row align-items-center">
                                                <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Shushank</h3>
                                                                    </div>
            
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                            Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                            Cannot wait to go back because this property deserves being visited more than once”
                                                            <br></br>
                                                            <br></br>
                                                            <br></br>
                                                            </p>
                                                            </div>
            
                                                    </div>
                                                </div>
                                                <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                                    <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                            <div className="testi-author mb-10">
                                                                <div className="review-content ta-info" >
                                                                    <div className='review-content-name'>
                                                                    <h3>Purva</h3>
                                                                    </div>
            
                                                                    <div className="qt-img">
                                                                    <img src={Aicon} alt="img"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review-icon mb-10">
                                                                <img src={Aicon1} alt="img"/>
                                                            </div>
                                                            <div className='mb-20'>
                                                            <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                            
                                                            </p>
                                                            </div>
            
                                                    </div>
                                                </div>
                                        </div>
                                    </Slider>
                             
                            )
                       :

                        isMobile == true ? 
          
                            (
                                
                                    <Slider className="slider-active" {...review}>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Adhideb</h3>
                                                                </div>

                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>

                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Ritesh</h3>
                                                                </div>

                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>

                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Kalpana</h3>
                                                                </div>

                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>

                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Chetan</h3>
                                                                </div>

                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>

                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Shushank</h3>
                                                                </div>

                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                        Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                        Cannot wait to go back because this property deserves being visited more than once”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>

                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Purva</h3>
                                                                </div>

                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>

                                                </div>
                                        </div>
                                    </div>
                                    </Slider>  
                                
                            )

                        :
                            (
                           
                                <Slider className="slider-active" {...review}>
                                <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Adhideb</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“We had a great time at Ankit's place. He was extremely responsive, flexible and helpful with local recommendations. The whole building has beautiful decor. The Scandinavian room was clean, well-furnished and had a beautiful view of Manalsu river flowing through the pine forests and mountains. The food was very delicious at the in-house restaurant. Highly recommended!”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Kalpana</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Well what I can say about the place, it was the right getaway we were looking from work. It has the view, kind host who knows the valley. For anything you want you can reach Ankit he will help you. The place have amazing garden place to chill in morning sunlight and for music jamming and barbecue in the evening. Rooms have all the necessities, ours had electric warming blanket too, which felt like such a luxury. Food was also amazing. Thank u for hosting us we will come again and surely recommend to family and friends. Perfect for couples.”
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Chetan</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Ankit did an amazing job with the place. The beautiful decor, cleanliness and location creates an atmosphere of comfort and relaxation that is simply unmatched. The culinary offerings are expertly crafted, thoughtfully presented and delicious. Ankit with his gracious staff cater to guests' every need with a genuine passion for hospitality. Finally, the hotel's location in a charming, peaceful with beautiful views in front and back. All in all, an outstanding destination for those seeking a refined and memorable experience.”
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                </div>
                                <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Ritesh</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Room was exactly how it appeared in photo. Tasteful interior, minimalist design, comfortable, relaxed is how I will describe the place. Ankit is always available at the property for any help. He is a warm and friendly host who makes the stay enjoyable by engaging in conversations. He scores a perfect 10 on his hospitality. The staff is very polite and well behaved. Food is too good. You can try all items on the menu. They are equally good. I recommend the property to everyone. You will not get disappointed. I hope I get a chance to stay again at “NinYanWays” ”
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Shushank</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Ankit's hotel is one of those rare view gems that allow you to bask in the peace of nature with both river and mountain views, while being stone's throw away from loads of cafes and eateries and the Old Manali market.
                                                    Ankit was a truly warm and welcoming host who not only accommodation an early check in for us but made sure to provide every small and big help to make our stay worth it.
                                                    Cannot wait to go back because this property deserves being visited more than once”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6 col-sm-12" >
                                            <div className="single-testimonial" style={{background: "#f7f5f1"}}>
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Purva</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“The best experience ever from the word go...The ambience, the location, the food, the cleanliness, the view and above all the hospitality of the owner Mr. Ankit. A beautiful place which has the facilities of a 5 star hotel. Special mention of the melodious music that flows thru the mountains as Ankit has an amazing playlist. All the rooms are theme based...A lot of love and thought has gone into making this abode. Can't wait to go back again. Don't think twice before booking this place. Nd yes do order their hand beaten coffee. Thanku ankit for being a perfect host. Shall see u soon .”
                                                    
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                </div>
                                </Slider>
                            
                            )


                    }
                    

                   
                </div>
            </section>

        </main>


        <FsLightbox
            toggler={toggler}
            sources={images}
            slide={activeImage}
        />

        {yShow &&
        <>
            <div className="mfp-bg mfp-ready"></div>
            <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex="-1" style={{overflow: "hidden"}}>
                <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                    <div className="mfp-content">
                        <div className="mfp-iframe-scaler">
                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setyShow(false)}>×</button>
                            <iframe className="mfp-iframe" src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1" frameborder="0" allowFullScreen=""></iframe>
                        </div>
                    </div>
                    <div className="mfp-preloader">Loading...</div>
                </div>
            </div>
        </>
        }
        {hShow &&
            <>  
                <div className="mfp-bg mfp-ready"></div>
                <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex="-1" style={{overflow: "hidden"}}>
                    <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                        <div className="mfp-content">
                            <div className="mfp-iframe-scaler">
                                <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => sethShow(false)}>×</button>
                                <iframe className="mfp-iframe" src="//www.youtube.com/embed/gyGsPlt06bo?autoplay=1" frameborder="0" allowFullScreen=""></iframe>
                            </div>
                        </div>
                        <div className="mfp-preloader">Loading...</div>
                    </div>
                </div>
            </>
        }
    </>
  )
}

export default Main