import React, { useState, useEffect } from 'react'
import { Link ,useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import { motion } from "framer-motion"
import { BrowserView, MobileView, TabletView, isBrowser, isMobile, isTablet } from 'react-device-detect';

import icon_whatsapp from '../../assets/img/icon/whatsapp.png'

const Main = () => {

    const [mobile, setmobile] = useState(false)
    const [show, setshow] = useState(false)
    const [show1, setshow1] = useState(false)
    const [show2, setshow2] = useState(false)
    const [show3, setshow3] = useState(false)
    const [show4, setshow4] = useState(false)

    const location = useLocation()
    const path = location.pathname

    useEffect(() => {
        window.scroll(0, 0)
        setmobile(false)
    }, [path]);

    return (
        <>
            <header className="header-area header-three">
                <div id="header-sticky" className="menu-area" style={{ padding: '6px'}}>
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2">
                                    <div className="logo" style={{ width: '95px'}}>
                                        <Link to="/"><img src="../../assets/img/logo/ninwan_new_logo.png" alt="logo" /></Link>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="main-menu text-center">
                                        <nav id="mobile-menu" >
                                            <ul>
                                                {/* <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/">Why Here</Link>
                                                    <ul>
                                                        <li><Link to="/rooms">Rooms</Link></li>
                                                        <li><Link to="/dining">Dining</Link></li>
                                                        <li><Link to="/location">Location</Link></li>
                                                        <li><Link to="/about">About</Link></li>
                                                        <li><Link to="/gallery">Gallery</Link></li>
                                                    </ul>
                                                </motion.li> */}
                                                <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/rooms">Rooms</Link>
                                                </motion.li>
                                                <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/dining">Dining</Link>
                                                </motion.li>
                                                <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/location">Location</Link>
                                                </motion.li>
                                                <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/season">Season</Link>
                                                </motion.li>
                                                <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/about">About</Link>
                                                </motion.li>
                                                <motion.li className="has-sub" whileHover={{ scale: 1.2 }}>
                                                    <Link to="/gallery">Gallery</Link>
                                                </motion.li>
                                                {/* <motion.li whileHover={{ scale: 1.2 }}  className="has-sub">
                                                    <Link to='/'>Why Now</Link>
                                                    <ul>
                                                        <li> <HashLink to="/season#spring" state={{season_name: 'spring'}}>Spring</HashLink></li>
                                                        <li> <HashLink to="/season#summer" state={{season_name: 'summer'}}>Summer</HashLink></li>
                                                        <li> <HashLink to="/season#fall" state={{season_name: 'fall'}}>Fall</HashLink></li>
                                                        <li> <HashLink to="/season#winter" state={{season_name: 'winter'}}>Winter</HashLink></li>
                                                    </ul>
                                                </motion.li> */}
                                                <motion.li whileHover={{ scale: 1.2 }}  className="has-sub">
                                                    <Link to='#'>Contact</Link>
                                                    <ul>
                                                        <li> <a href="https://www.airbnb.co.in/users/show/404182738" target='_blank' rel="noopener noreferrer">Reviews</a></li>
                                                        {/* <li> <a href="https://wa.me/8988999951/?text=Hi" target='_blank' rel="noopener noreferrer" >Contact</a></li> */}
                                                        <li> <a href="https://wa.me/8988999951/?text=Hi" target='_blank' rel="noopener noreferrer" >Reserve</a></li>
                                                        
                                                    </ul>
                                                </motion.li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <motion.div whileHover={{ scale: 1.2 }} className="col-xl-2 col-lg-2 d-none d-lg-block">
                                    <a href="https://wa.me/8988999951/?text=Hi" target='_blank' rel="noopener noreferrer" className="top-btn p-2 " style={{fontSize:"20px", backgroundColor: 'transparent', textAlign: 'right'}}><img src={icon_whatsapp} alt="whatsap_img" style={{width: '40px'}} /></a>
                                    {/* <Link to="/contact" className="top-btn mt-10 mb-10">reservation </Link> */}
                                </motion.div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar"><Link to="#nav" className={`meanmenu-reveal ${mobile && "meanclose"}`} onClick = {() => setmobile(mobile === true ? false : true)} style={{ right: "0px" , left: "auto", textAlign: "center", textIndent: "0px", fontSize: "18px"}}>
                                            {mobile ?
                                            "X"
                                            :
                                            <>
                                                <span><span><span></span></span></span>
                                                </>
                                            }
                                        </Link>
                                            <nav className="mean-nav">
                                                {mobile && 
                                                <ul style={{ display: mobile ? "block" : "none" }}>
                                                    <li className="has-sub">
                                                        <Link to="/rooms">Rooms</Link>
                                                        {/* <ul style={{ display: show ? "block" : "none" }}>
                                                            <li><Link to="/rooms">Rooms</Link></li>
                                                            <li><Link to="/dining">Dining</Link></li>
                                                            <li><Link to="/location">Location</Link></li>
                                                            <li><Link to="/about">About</Link></li>
                                                            <li><Link to="/gallary">Gallery</Link></li>
                                                        </ul> */}
                                                        {/* <Link to="#" className={`mean-expand ${show && "mean-clicked"}`}  onClick = {() => setshow(show === true ? false : true)} style={{ fontSize: "18px" }}>{show ? "-" : "+" }</Link> */}
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/dining">Dining</Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/location">Location</Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/season">Season</Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/about">About</Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/gallery">Gallery</Link>
                                                    </li>
                                                    {/* <li className="has-sub">
                                                        <Link to="#">Why Now</Link>
                                                        <ul style={{ display: show1 ? "block" : "none" }}>
                                                            <li> <HashLink to="/season#spring" state={{season_name: 'spring'}}> Spring</HashLink></li>
                                                            <li> <HashLink to="/season#summer" state={{season_name: 'summer'}}> Summer</HashLink></li>
                                                            <li> <HashLink to="/season#fall" state={{season_name: 'fall'}}> Fall</HashLink></li>
                                                            <li> <HashLink to="/season#winter" state={{season_name: 'winter'}}> Winter</HashLink></li>
                                                        </ul>
                                                        <Link to="#" className={`mean-expand ${show1 && "mean-clicked"}`} onClick = {() => setshow1(show1 === true ? false : true)} style={{ fontSize: "18px" }}>{show1 ? "-" : "+" }</Link></li> */}
                                                    <li className="has-sub">
                                                        <Link to="#">Contact</Link>
                                                        <ul style={{ display: show2 ? "block" : "none" }}>
                                                            <li> <a href="https://www.airbnb.co.in/users/show/404182738" target='_blank' rel="noopener noreferrer">Reviews</a></li>
                                                            {/* <li> <a href="https://wa.me/8988999951/?text=Hi" target='_blank' rel="noopener noreferrer" >Contact</a></li> */}
                                                            <li> <a href={ isTablet ? 'https://wa.me/8988999951/?text=Hi' : 'https://wa.me/+91-8988999951/?text=Hi'} target='_blank' rel="noopener noreferrer" >Reserve</a></li>
                                                        </ul>
                                                        <Link to="#" className={`mean-expand ${show2 && "mean-clicked"}`} onClick = {() => setshow2(show2 === true ? false : true)}  style={{ fontSize: "18px" }}>{show2 ? "-" : "+" }</Link></li>

                                                </ul> }
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Main