import React, {useState} from "react";
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player/lazy'
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';


import bgVideo from  "../../assets/video/bgvideo.mp4"
import bbq from '../../assets/img/bg/dining-bg-large.jpg'
import menu from '../../assets/img/bg/menu-sec-bg.jpg'

import {motion} from 'framer-motion'
import Slider from "react-slick"
import avatar1 from "../../assets/img/testimonial/testi_avatar.png"
import avatar2 from "../../assets/img/testimonial/testi_avatar_02.png" 
import avatar3 from "../../assets/img/testimonial/testi_avatar_03.png"
import Aicon from "../../assets/img/testimonial/qt-icon.png"
import Aicon1 from "../../assets/img/testimonial/review-icon.png"
import Booking from "../../assets/img/bg/booking-img.png"
import Play from "../../assets/img/bg/play-button.png"


import BGImg1 from "../../assets/img/slider/slider-res-sec3-bg3.jpg";
import BGImg2 from "../../assets/img/slider/slider-res-sec3-bg2.jpg";
import BGImg3 from "../../assets/img/slider/slider-res-sec3-bg1.jpg";

import res_sec4_bg from '../../assets/img/bg/res-sec4-bg.jpg'

import aboutSec5Left from "../../assets/img/features/res-sec5-left4.jpg";
import aboutSec5Left2 from "../../assets/img/features/res-sec5-left5.jpg";
import aboutSec5Left3 from "../../assets/img/features/res-sec5-left6.jpg";

import BGTest from "../../assets/img/bg/review-bg.jpg";
import videoLeft from '../../assets/video/bbq-video-trimmed.mp4'

import '../../assets/css/overlap.css'


export default function Restaurants (){
    const [hShow, sethShow] = useState()
    const [animation, setanimation] = useState()


    const home = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        autoplaySpeed:2000,
        centerMode: true,
        centerPadding: "200px",
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "200px",
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const sec3 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const sec5 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const testimonial = {
        initialIndex: 2,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const review_carousal = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    let screenWidth = window.innerWidth;

    return(
        <>
           <main>

           <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${bbq})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>Dining</h2>    
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Dining</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           <section id="res-sec2" className="dining-video-sec main-content-section blog-area p-relative fix pt-120 pb-120">
                 <div className="animations-02"><img src="../../assets/img/bg/table-etiquette.png" style={{opacity: '0.1'}} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center"> 
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <motion.div className="s-about-img p-relative  wow fadeInLeft animated"   >
                    
                                <div className="left-video-block">
                                    
                                    <ReactPlayer url={videoLeft} loop={true} muted={true} playing={true} id='video-bg' width='100%' height='100%' />
                                 </div>
                            </motion.div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title left-video-content">  
                                    <motion.h2 className='pb-05'  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Dining With Us</motion.h2>                                   
                                
                                   <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Wake up to a leisurely morning in bed, and a hearty breakfast, before dropping in on the garden, overlooking the pine forest by the banks of Manalsu, in the late afternoon. Simply order online from our customizable menus - your favourite cuisines including Continental, Tandoori, Indian and Chinese - to enjoy the hassle-free room service. There is a garden café in which to dine as well, where you can also work, socialize or just relax in the lap of nature herself.</motion.p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  

            <BrowserView>
                <section id="res-sec3-d" className="main-content-section slider-area res-sec-food-slider fix p-relative pt-120 pb-110" style={{background: "#f7f5f1"}}>
                    <Slider className="slider-active" {...home}>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg2})`}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg3})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2 slider-img-box">
                            <div className="single-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg1})`, background_size: "cover"}}>
                            </div>
                        </div>
                    </Slider>
                </section>

            </BrowserView>   

            <MobileView>
                <section id="res-sec3-m" className="main-content-section slider-area res-sec-food-slider fix p-relative pt-90 pb-90" style={{background: "#f7f5f1"}}>
                    <Slider className="row slider-active restaurant-sec3-slider" {...sec3}>
                        <div className="col-xl-2">
                            <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg2})`}}>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg3})`, background_size: "cover"}}>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="restaurant-sec3-slider-img single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(${BGImg1})`, background_size: "cover"}}>
                            </div>
                        </div>
                    </Slider>
                </section>
            </MobileView>


            <section id="res-sec4" className="main-content-section about-area about-p pt-90 pb-90 p-relative fix"  style={{ background : `url(${menu})`}} >
                {/* <div className="animations-02"><img src="../../assets/img/bg/dining.png" style={{opacity:'0.1'}} alt="contact-bg-an-02"/></div> */}
                <div className="container">
                    <div className="row res-sec4 justify-content-center align-items-center">

					    <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25" style={{textAlign: 'center'}}>  
                                    {/* <h5>About Us</h5> */}
                                    <motion.h2  style={{ color: '#fff'}} initial={{ opacity: 0 , x: -100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeIn" } }}>NinYanWays Daily Menu - Spring ‘23</motion.h2>                                   
                                </div>

                                <div className="col-xl-12 mt-30">    
                                        <div className="section-title center-align text-center">
                                            <a href="https://ninyanways.in/?restaurantId=sypNHoLQA" target='_blank' rel="noopener noreferrer" className="btn ss-btn">Check our Menu</a>
                                        </div>
                                    </div> 
                            </div>
                        </div>

                        {/* <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={res_sec4_bg} alt="img" />   
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <section id="res-sec5" className="main-content-section about-area about-p pt-90 pb-90 p-relative fix" style={{background: "#f7f5f1"}}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="res-ambience-slider-container col-lg-6 col-md-12 col-sm-12">
                        <Slider className="row slider-active restaurant-sec5-slider" {...sec5}>
                          
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left} alt="img" />   
                            </div>

                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left2} alt="img" />   
                            </div>

                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left3} alt="img" />   
                            </div>

                        </Slider>  
                        </div>
					    <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">  
                                    <motion.h2  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Ambience Options</motion.h2>                                   
                                </div>
                                <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Meals and snacks can be enjoyed from the comfort of your room, in the lobby or outside in the garden.</motion.p>
 
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="about-area room-first-sec dining-overlap about-p pt-120 pb-80 p-relative fix" >
                <div className="container">
                    <div className="dining-overlap-sec row justify-content-center">
                        <div className="dining-first-img-sec content-img dining-content-img col-lg-6 col-md-12 col-sm-12">


                            {/* <div className="content-img-box dining-content-img-box s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left} alt="img" />   
                            </div> */}


                        <Slider className="row slider-active restaurant-sec5-slider" {...sec5}>
                            <div className="content-img-box dining-content-img-box s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left} alt="img" />   
                            </div>

                            <div className="content-img-box dining-content-img-box s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left2} alt="img" />   
                            </div>

                            <div className="content-img-box dining-content-img-box s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={aboutSec5Left3} alt="img" />   
                            </div>
                        </Slider>

                        </div>
					    <div className="content-desc dining-content-desc col-lg-6 col-md-12 col-sm-12" style={{background: "#F7F5F1"}}>
                            <div className="about-content s-about-content  wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title">  
                                    <motion.h2 className="pb-05" initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Ambience Options</motion.h2>                                   
                                
                                    <motion.p  initial={{ opacity: 0 , x: 100}}  whileInView={{opacity: 1, x:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Meals and snacks can be enjoyed from the comfort of your room, in the lobby or outside in the garden.</motion.p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="res-sec6" className="main-content-section blog-area p-relative fix pt-80 pb-90" >
                 <div className="animations-02"><img src="../../assets/img/bg/dinner-table.png" style={{opacity:'0.1'}} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-12">
                            <div className="section-title service-hour-section center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                    
                                <h2>
                                    Service Hours
                                </h2>
                                <br></br>

                                <h3>Kitchen Timings:</h3>
                                <h6>8:30am-3:00pm</h6>
                                <h6>5:00pm-10:00pm</h6>

                                <br></br>
                                <h3>Room service is available from 8:30am untill 10:30pm.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 


            <section className="main-content-section testimonial-area pt-120 pb-120 p-relative fix"  style={{background: "#f7f5f1"}}>
                <div className="container">
                    <div className="row">
                         <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                <motion.h5  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Restaurant Reviews</motion.h5>
                                <motion.h2  initial={{ opacity: 0 , y: -100}}  whileInView={{opacity: 1, y:0 , transition:{ duration: 1, ease: "easeInOut" } }}>Culinary Feedback from Past Guests</motion.h2>
                            </div>
                        </div>
                    </div>

                    {

                        isTablet === true ? 

                            screenWidth > 992 && screenWidth < 1200 ? 
                            (
                    
                                <Slider className="slider-active" {...review_carousal}>
                                    <div className="review-slider row align-items-center">
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Emily</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Samar</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Amod</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“We had a wonderful stay at NinYanWays. Just like its name, the property is quite unique but right at the heart of Manali with all the good restaurants and cafes within walking distance. Despite the location, it still feels little secluded and away from chaos with amazing views of mountains, forest and a river. Ankit is an incredible host, excellent at communication, knows what his guests want. The staffs are extremely polite, humble and helpful. Food is amazing. Shoutout to Bro and Sky (the dogs)…. Highly recommended. PS: Fireplace and bed warmers will spoil you.”
                                                            
                                                            
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Sunidhi</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Amazing stay! The place definitely looks better in person as compared to the photos. There are lots of beautiful scenery to explore nearby. The food here is excellent, we loved the brownie with ice cream and had it every day. Overall, Ankit is a great host and we had a beautiful time here. ”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Siddhant</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“lovely lovely place. highly recommended. amazing location, sumptuous food and very cosy rooms and the view was so. we spent 2 days in the room with the views and the food cooked in the hotel. everything was just perfect. will love to come back.”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Naman</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“My best stay till date. The property was very nicely designed giving attention to detail. The host, Ankit was very friendly and made us very comfortable. The food served was very delicious and the barbecue was cherry on the top. Had a really great time”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                    </div>
                                </Slider>
                            )
                                :
                            (
                                <Slider className="slider-active" {...review_carousal}>
                                    <div className="review-slider row align-items-center">
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Emily</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Samar</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Amod</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“We had a wonderful stay at NinYanWays. Just like its name, the property is quite unique but right at the heart of Manali with all the good restaurants and cafes within walking distance. Despite the location, it still feels little secluded and away from chaos with amazing views of mountains, forest and a river. Ankit is an incredible host, excellent at communication, knows what his guests want. The staffs are extremely polite, humble and helpful. Food is amazing. Shoutout to Bro and Sky (the dogs)…. Highly recommended. PS: Fireplace and bed warmers will spoil you.”
                                                            <br></br>
                                                            <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Sunidhi</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“Amazing stay! The place definitely looks better in person as compared to the photos. There are lots of beautiful scenery to explore nearby. The food here is excellent, we loved the brownie with ice cream and had it every day. Overall, Ankit is a great host and we had a beautiful time here. ”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                        </div>
                                    </div>
                                    <div className="review-slider row align-items-center">
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Siddhant</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“lovely lovely place. highly recommended. amazing location, sumptuous food and very cosy rooms and the view was so. we spent 2 days in the room with the views and the food cooked in the hotel. everything was just perfect. will love to come back.”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                            <div className="review-box col-lg-4 col-md-6" >
                                                <div className="single-testimonial">
                                                        <div className="testi-author mb-10">
                                                            <div className="review-content ta-info" >
                                                                <div className='review-content-name'>
                                                                <h3>Naman</h3>
                                                                </div>
            
                                                                <div className="qt-img">
                                                                <img src={Aicon} alt="img"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-icon mb-10">
                                                            <img src={Aicon1} alt="img"/>
                                                        </div>
                                                        <div className='mb-20'>
                                                        <p>“My best stay till date. The property was very nicely designed giving attention to detail. The host, Ankit was very friendly and made us very comfortable. The food served was very delicious and the barbecue was cherry on the top. Had a really great time”
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        </p>
                                                        </div>
            
                                                </div>
                                            </div>
                                    </div>
                                </Slider>
                            )
                        :

                        isMobile ? 
                            (
                                <Slider className="slider-active" {...review_carousal}>
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Emily</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Samar</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Amod</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“We had a wonderful stay at NinYanWays. Just like its name, the property is quite unique but right at the heart of Manali with all the good restaurants and cafes within walking distance. Despite the location, it still feels little secluded and away from chaos with amazing views of mountains, forest and a river. Ankit is an incredible host, excellent at communication, knows what his guests want. The staffs are extremely polite, humble and helpful. Food is amazing. Shoutout to Bro and Sky (the dogs)…. Highly recommended. PS: Fireplace and bed warmers will spoil you.”
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
        
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial" >
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Sunidhi</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Amazing stay! The place definitely looks better in person as compared to the photos. There are lots of beautiful scenery to explore nearby. The food here is excellent, we loved the brownie with ice cream and had it every day. Overall, Ankit is a great host and we had a beautiful time here. ”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Siddhant</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“lovely lovely place. highly recommended. amazing location, sumptuous food and very cosy rooms. and the view was so. we spent 2 days in the room with the views and the food cooked in the hotel. everything was just perfect. will love to come back.”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
                                <div className="review-slider row align-items-center">
                                    <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Naman</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“My best stay till date. The property was very nicely designed giving attention to detail. The host, Ankit, was very friendly and made us very comfortable. The food served was very delicious and the barbecue was cherry on the top. Had a really great time”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    
                                                    </p>
                                                    </div>
        
                                            </div>
                                    </div>
                                </div>
                                </Slider>  
                            )
                        :

                        (
                    
                            <Slider className="slider-active" {...review_carousal}>
                                <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Emily</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Samar</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“The best place in Manali! We thoroughly enjoyed our stay here, from the very start Ankit was great, super friendly, kind and responsive. A beautiful hotel, stunning views and garden area with great music and outdoor fires. Food was incredible. Service was amazing. Little small personable touches which made it stand out. Spotless room, comfy bed with heated warmers. Everything has been thought through and done to the very best standard. A must stay - would highly recommend!”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Amod</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“We had a wonderful stay at NinYanWays. Just like its name, the property is quite unique but right at the heart of Manali with all the good restaurants and cafes within walking distance. Despite the location, it still feels little secluded and away from chaos with amazing views of mountains, forest and a river. Ankit is an incredible host, excellent at communication, knows what his guests want. The staffs are extremely polite, humble and helpful. Food is amazing. Shoutout to Bro and Sky (the dogs)…. Highly recommended. PS: Fireplace and bed warmers will spoil you.”
                                                        <br></br>
                                                        <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                </div>
                                <div className="review-slider row align-items-center">
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Sunidhi</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“Amazing stay! The place definitely looks better in person as compared to the photos. There are lots of beautiful scenery to explore nearby. The food here is excellent, we loved the brownie with ice cream and had it every day. Overall, Ankit is a great host and we had a beautiful time here. ”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Siddhant</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“lovely lovely place. highly recommended. amazing location, sumptuous food and very cosy rooms and the view was so. we spent 2 days in the room with the views and the food cooked in the hotel. everything was just perfect. will love to come back.”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                        <div className="review-box col-lg-4 col-md-6" >
                                            <div className="single-testimonial">
                                                    <div className="testi-author mb-10">
                                                        <div className="review-content ta-info" >
                                                            <div className='review-content-name'>
                                                            <h3>Naman</h3>
                                                            </div>
        
                                                            <div className="qt-img">
                                                            <img src={Aicon} alt="img"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-icon mb-10">
                                                        <img src={Aicon1} alt="img"/>
                                                    </div>
                                                    <div className='mb-20'>
                                                    <p>“My best stay till date. The property was very nicely designed giving attention to detail. The host, Ankit was very friendly and made us very comfortable. The food served was very delicious and the barbecue was cherry on the top. Had a really great time”
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    
                                                    </p>
                                                    </div>
        
                                            </div>
                                        </div>
                                </div>
                            </Slider>
                        )

                    }
                </div>
            </section>

           </main>
        </>
    )
}